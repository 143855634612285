import React, { Component } from 'react';
import { createPortal } from 'react-dom';

class Portal extends Component {
  constructor(props) {
    super(props);
    this.isBrowser = typeof document !== 'undefined';
    if (this.isBrowser) {
      this.el = document.createElement('div');
      this.modalRoot = document.body;
    }
  }

  componentDidMount() {
    if (this.isBrowser) {
      this.modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (this.isBrowser) {
      this.modalRoot.removeChild(this.el);
    }
  }

  render() {
    if (this.isBrowser) {
      return createPortal(this.props.children, this.el);
    }
    return <span data-message="Portals is not supported by SSR" />;
  }
}

export default Portal;
