import { ReactElement, CSSProperties, useEffect, useState, useRef, useCallback, forwardRef, PropsWithChildren } from 'react';

import ProgressIndicator from 'components/ProgressIndicator';
import classNames from 'classnames';
import XCircle from '@components/Icons/XCircle';
import { m } from 'framer-motion';
import isInIframe from '@helpers/isInIframe';
import isAndroid from '@helpers/isAndroid';
import isIOS from '@helpers/isIOS';
import Spacer from '@components/Spacer';
import { useIsMobile } from '@/hooks';
import s from './Modal.module.scss';
import Portal from '../Portal';

type Props = {
  onClose?: () => void;
  title?: string;
  isFetching?: boolean;
  isAi?: boolean;
  isRightDrawer?: boolean;
  hasForm?: boolean;
  isFullScreen?: boolean;
  actions?: Array<ReactElement>;
  hasPadding?: boolean;
  hasUnsavedChanges?: boolean;
  closeIcon?: ReactElement;
  containerStyle?: CSSProperties;
  zIndex?: number;
  hideBottomInset?: boolean;
  shouldCloseOnOutsideClick?: boolean;
};

// eslint-disable-next-line react/display-name
const Modal = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      onClose,
      closeIcon = <XCircle weight="fill" size={24} color="var(--color-gray-100)" />,
      title,
      children,
      isFetching = false,
      isAi,
      isRightDrawer,
      actions = [],
      hasForm: hasFormExternal,
      hasUnsavedChanges = false,
      isFullScreen = false,
      hasPadding = false,
      containerStyle = {},
      zIndex,
      hideBottomInset,
      shouldCloseOnOutsideClick = true,
    },
    ref,
  ) => {
    const isPinnedToBottom = useIsMobile();

    const [hasForm, setHasForm] = useState(hasFormExternal ?? false);
    const [isIconVisible, setIsIconVisible] = useState(false);
    const onCloseRef = useRef(onClose);
    onCloseRef.current = onClose;

    const modalActions = [...actions];

    const style: CSSProperties = {};
    let containerStyleOverrides: CSSProperties = {};

    if (isFullScreen) {
      style.maxWidth = '100vw';
      style.maxHeight = 'min(min(100vh, 100dvh), var(--host-height))';
      containerStyleOverrides = {
        ...style,
        ...containerStyle,
      };
    }

    if (isPinnedToBottom && !isInIframe()) {
      containerStyleOverrides.position = 'fixed';
      containerStyleOverrides.bottom = '0';
      containerStyleOverrides.width = '100vw';
      containerStyleOverrides.maxWidth = '100vw';
      containerStyleOverrides.borderBottomLeftRadius = '0';
      containerStyleOverrides.borderBottomRightRadius = '0';
    }

    useEffect(() => {
      setIsIconVisible(Boolean(onClose));
    }, [onClose]);

    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }, []);

    useEffect(() => {
      if (hasFormExternal === undefined) {
        const modalBody = document.getElementById('modal-body');
        if (modalBody) {
          const label = modalBody.querySelector('label');
          setHasForm(!!label);
        }
      } else {
        setHasForm(hasFormExternal);
      }
    }, [hasFormExternal]);

    // trap focus in modal
    useEffect(() => {
      const elementsToSelect = '#dialog a[href]:not([disabled]), #dialog button:not([disabled]), #dialog textarea:not([disabled]), #dialog input[type="text"]:not([disabled]), #dialog input[type="radio"]:not([disabled]), #dialog input[type="checkbox"]:not([disabled]), #dialog select:not([disabled])';

      const firstFocusableElement = document.querySelector(elementsToSelect);

      const FocusableHTMLElements = HTMLButtonElement || HTMLAnchorElement || HTMLInputElement || HTMLSelectElement || HTMLTextAreaElement;

      if (firstFocusableElement instanceof FocusableHTMLElements) {
        firstFocusableElement.focus();
      }

      const handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
          if (onCloseRef.current) {
            onCloseRef.current();
          }
        }
        if (event.key === 'Tab') {
          const focusableElements = document.querySelectorAll(elementsToSelect);

          const lastFocusableElement = focusableElements[focusableElements.length - 1];

          if (event.shiftKey) {
            if (document.activeElement === firstFocusableElement && lastFocusableElement instanceof FocusableHTMLElements) {
              event.preventDefault();
              lastFocusableElement.focus();
            }
          } else if (document.activeElement === lastFocusableElement) {
            event.preventDefault();
            if (firstFocusableElement instanceof FocusableHTMLElements) {
              firstFocusableElement.focus();
            }
          }
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    const handleClickMask: React.MouseEventHandler<HTMLDivElement> = useCallback(
      event => {
        if (event.target === event.currentTarget) {
          if (hasUnsavedChanges || hasForm) {
            if (window.confirm('Are you sure you want to close this modal? Changes you made may not be saved.')) {
              if (onCloseRef.current) {
                onCloseRef.current();
              }
            }
          } else if (onCloseRef.current && shouldCloseOnOutsideClick) {
            onCloseRef.current();
          }
        }
      },
      [hasForm, hasUnsavedChanges, shouldCloseOnOutsideClick],
    );

    const handleClickIcon: React.MouseEventHandler<HTMLButtonElement> = useCallback(event => {
      event.stopPropagation();
      event.preventDefault();
      if (onCloseRef.current) {
        onCloseRef.current();
      }
    }, []);

    return (
      <Portal>
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.2,
          }}
          className={classNames(s.modal__backdrop)}
          style={{ ...(isInIframe() && { alignItems: 'flex-start' || undefined, paddingTop: 24 }), zIndex }}
          id="modal-backdrop"
          onClick={handleClickMask}
        >
          {isAi && (
            <m.img
              animate={{
                filter: ['hue-rotate(-90deg)', 'hue-rotate(0deg)'],
                opacity: [0.3, 1],
                transition: {
                  duration: 4,
                  repeat: Infinity,
                  repeatType: 'mirror',
                },
              }}
              className={s['image-backdrop']}
              src="/images/dashboard/ai-modal-backdrop.webp"
            />
          )}
          <m.div
            id="dialog"
            initial={(isAi || isRightDrawer) ? { x: 100 } : { y: 40 }}
            animate={(isAi || isRightDrawer) ? { x: 0 } : { y: 0 }}
            transition={{
              ease: [0.25, 1, 0.5, 1],
            }}
            className={classNames(s.modal__container, {
              [s['modal__container--has-padding']]: hasPadding,
              [s['modal__container--ai']]: isAi,
              [s['modal__container--right']]: isRightDrawer,
            })}
            style={containerStyleOverrides}
          >
            {isFetching && <ProgressIndicator isAbsolute />}
            {title !== null && title !== undefined && (
              <div className={s.header}>
                {isIconVisible && (
                  <button id="modalCloseButton" className={s.closeButton} onClick={handleClickIcon}>
                    {closeIcon}
                  </button>
                )}
                <span className={classNames("body-sm", {
                  [s['title--ai']]: isAi,
                })}>{title}</span>
              </div>
            )}
            <m.div id="modal-body" layoutScroll className={classNames(s.modal__body, (isAi || isRightDrawer) && s['modal__body-right'])} style={style} ref={ref}>
              {title && <Spacer size="24px" />}
              {children}
              {((isPinnedToBottom && !hideBottomInset && !isInIframe()) || (isPinnedToBottom && isAndroid()) || (isPinnedToBottom && isIOS())) && (
                <Spacer size="60px" />
              )}
            </m.div>
            {modalActions.length > 0 && <div className={s.modal__actions}>{modalActions}</div>}
          </m.div>
        </m.div>
      </Portal>
    );
  },
);

export default Modal;
