import { FC } from 'react';

import s from './RenderLoomVideo.module.scss';

const RenderLoomVideo: FC<{ url: string, isFullWidth?: boolean }> = ({ url, isFullWidth }) => (
  <div key={url} className={s['comment-msg__msg-video']} style={!isFullWidth && { maxWidth: 400 } || undefined}>
    <iframe src={url.replace('share', 'embed')} frameBorder="0" width="100%" height="300" allowFullScreen />
  </div>
);

export default RenderLoomVideo;