import VideoCamera from '@components/Icons/VideoCamera';
import Microphone from '@components/Icons/Microphone';
import Image from '@components/Icons/Image';
import File from '@components/Icons/File';
import s from './MediaIcon.module.scss';

type Props = {
  type: string;
};

const MediaIcon: React.FC<Props> = ({ type }) => {
  if (type.includes('video')) {
    return (
      <div className={s.wrapper}>
        <VideoCamera weight="fill" size={16} color="var(--color-primary)" />
      </div>
    );
  }
  if (type.includes('audio')) {
    return (
      <div className={s.wrapper}>
        <Microphone weight="fill" size={16} color="var(--color-primary)" />
      </div>
    );
  }
  if (type.includes('image')) {
    return (
      <div className={s.wrapper}>
        <Image weight="fill" size={16} color="var(--color-primary)" />
      </div>
    );
  }
  return (
    <div className={s.wrapper}>
      <File weight="fill" size={16} color="var(--color-primary)" />
    </div>
  );
};

export default MediaIcon;
