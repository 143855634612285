import classNames from 'classnames';
import Buttons from '@components/Buttons';
import TextEllipsis from '@components/TextEllipsis';
import FileUploadIndicator from '@components/FileUploadIndicator';
import XCircle from '@components/Icons/XCircle';
import { mixpanelTrack } from '@providers/Mixpanel';
import Image from 'next/image';
import { Job, JobStatus, JobType } from '../../Context';
import StatusIcon from '../StatusIcon';
import MediaIcon from '../MediaIcon';
import useJobContext from '../../useJobContext';

import s from './AsyncJob.module.scss';

type AsyncJobProps = {
  job: Job;
  isSmall?: boolean;
};

const typesWithProgress = [JobType.MESSAGE_MEDIA_UPLOAD, JobType.TEST_JOB];
const statusWithRetry = [JobStatus.CANCELED, JobStatus.FAILED];

export default function AsyncJob({ job, isSmall }: AsyncJobProps): JSX.Element {
  const { jobs, removeJob } = useJobContext();
  const { status, progress, dependsOn, type, mediaType, title } = job;

  const cancelJob = (): void => {
    mixpanelTrack('Async Job Cancel pressed', {
      ...(job.metadata || {}),
      correlationId: job.correlationId,
      type,
    });
    job.cancel();
    removeJob(job);
  };

  const retryJob = (): void => {
    mixpanelTrack('Async Job Retry pressed', {
      ...(job.metadata || {}),
      correlationId: job.correlationId,
      type,
    });
    job.retry();
    setTimeout(() => {
      const parentJob = jobs.find(j => j.dependsOn?.some(i => i.id === job.id));
      if (parentJob) {
        parentJob.retry();
      }
    }, 1000);
  };

  const dependingJobs = jobs
    .filter(({ id }) => dependsOn?.map(({ id: dependId }) => dependId).includes(id))
    .filter(j => (job.type === JobType.MESSAGE_MEDIA_UPLOAD ? j.type !== JobType.MESSAGE_MEDIA_UPLOAD : true));

  const getJobTitle = (): string => {
    let jobTitle = title;
    if (typesWithProgress.includes(type)) {
      if (status === JobStatus.IN_PROGRESS) {
        jobTitle += ' uploading';
      }
      if (status === JobStatus.COMPLETED) {
        jobTitle += ' uploaded';
      }
      if (status === JobStatus.CANCELED) {
        jobTitle += ' upload canceled';
      }
      if (status === JobStatus.FAILED) {
        jobTitle += ' upload failed';
      }
    } else {
      if (status === JobStatus.IN_PROGRESS) {
        jobTitle += ' publishing...';
      }
      if (status === JobStatus.COMPLETED) {
        jobTitle += ' published';
      }
      if (status === JobStatus.CANCELED) {
        jobTitle += ' canceled';
      }
      if (status === JobStatus.FAILED) {
        jobTitle += ' failed';
      }
    }
    return jobTitle;
  };

  return (
    <div className={classNames(s.container, { [s['container--small']]: isSmall })}>
      <div className={classNames('body-sm', s.top)}>
        {(typesWithProgress.includes(type) && mediaType && (
          <>
            {mediaType.includes('image') && status === JobStatus.COMPLETED && job.uploadMedia?.url
              ? (
                <Image src={job.uploadMedia.url} alt="" width={24} height={24} style={{ backgroundColor: 'var(--color-gray-25)', borderRadius: 4, objectFit: 'cover' }} />
              )
              : (
                <MediaIcon type={mediaType} />
              )}
          </>
        ))
        || <StatusIcon status={status} />}

        <div
          className={classNames(
            'caption',
            s.title,
            { [s[`title--strong`]]: status !== JobStatus.IN_PROGRESS },
            { [s[`title--error`]]: status === JobStatus.FAILED },
          )}
        >
          <TextEllipsis isNotExpandable>{getJobTitle()}</TextEllipsis>
          {status === JobStatus.IN_PROGRESS && typesWithProgress.includes(type) && (
            <FileUploadIndicator className={s.progress} showTop={false} uploadPercentage={progress} />
          )}
        </div>
        {statusWithRetry.includes(status) && (
          <Buttons isWrapper className={s.retry} onClick={retryJob}>
            Try again
          </Buttons>
        )}
        {(status === JobStatus.IN_PROGRESS || status === JobStatus.FAILED)
          && !dependingJobs.filter(dj => dj.status === JobStatus.IN_PROGRESS).length && (
          <Buttons
            isExtraSmall
            onClick={cancelJob}
            className={s.cancel}
            icon={typesWithProgress.includes(type) ? <XCircle weight="fill" /> : undefined}
          >
              Cancel
          </Buttons>
        )}
      </div>
      {dependingJobs.map(subJob => (
        <AsyncJob key={subJob.id} job={subJob} isSmall />
      ))}
    </div>
  );
}
