import { FC, useContext, useEffect } from 'react';
import CustomSelect from '@components/FormFields/CustomSelect';
import teamsContext from '@providers/Teams/TeamsContext';
import useNoodleApi from '@hooks/useNoodleApi';
import classNames from 'classnames';
import { getConfidoBankAccounts } from '@tsClient/creators';
import Confido from '@components/Icons/Confido';
import s from './ConfidoBankAccountSelector.module.scss';

type Props = {
  value?: string;
  onChange: (newValue: string | null) => void | Promise<void>;
  className?: string;
};

const ConfidoBankAccountSelector: FC<Props> = ({ value, onChange, className }) => {
  const { creatorId, currentTeamOwner } = useContext(teamsContext);
  const { data: confidoBankAccounts, getData: getConfidoBankAccountsFn } = useNoodleApi(getConfidoBankAccounts);

  useEffect(() => {
    if (creatorId && currentTeamOwner?.usesConfido) {
      getConfidoBankAccountsFn({ creatorId });
    }
  }, [getConfidoBankAccountsFn, creatorId, currentTeamOwner]);

  if (!creatorId || !currentTeamOwner?.usesConfido || !confidoBankAccounts || confidoBankAccounts.length === 0) {
    return null;
  }

  const handleChange = (newValue: string): void => {
    onChange(newValue);
  };

  const options = [
    ...(confidoBankAccounts || []).map(account => ({
      title: `${account.nickname} (*${account.lastFour})`,
      value: account.id,
    })),
  ];

  return (
    <div className={classNames(s.wrapper, className)}>
      <Confido />
      <CustomSelect
        options={options}
        value={value || confidoBankAccounts.find((account) => account.isDefault)?.id || confidoBankAccounts[0].id}
        id="confidoBankAccountId"
        onChange={handleChange}
        label="When you make sales of this product, the funds will be sent to this bank account:"
        native
      />
    </div>
  );
};

export default ConfidoBankAccountSelector;
