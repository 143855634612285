import { Descendant } from 'slate';
import deserializeHtml from './deserializeHtml';

const htmlTextToSlate = (initialHtml: string | undefined | null): { children: Descendant[] }[] => {
  // end up with #text fields in the dom from DOMParser that get converted to
  // { "text": "\n      " }
  // But we can't have  text nodes at the top
  // This causes in the SlateEditor.
  // Quick fix is to remove any top level texts. real fix probably wrap in a paragraph...
  let html = initialHtml;
  html = html || '<p></p>';

  // Trim whitespace before and after newlines.
  html = html.replace(/\s*\n\s*/g, '\n');

  const document = new DOMParser().parseFromString(html, 'text/html');
  const asSlate = deserializeHtml(document.body) as { children: Descendant[] }[];
  return asSlate;
};

export default htmlTextToSlate;