import { FC, useContext, useState } from 'react';
import * as ApiModels from '@typings/api-models';
import { NoodleProductTypes } from '@typings/api-models';
import Spacer from '@components/Spacer';
import RadioInput from '@components/FormFields/RadioInputCards';
import Coin from '@components/Icons/Coin';
import { Currency, StripePriceUsageType } from '@typings/graphql-models';
import Coins from '@components/Icons/Coins';
import Button from '@components/DesignLibrary/Button';
import PriceInput from '@components/FormFields/PriceInput';
import CustomSelect from '@components/FormFields/CustomSelect';
import TextInput from '@components/FormFields/TextInput';
import InputNumberWithControls from '@components/FormFields/InputNumberWithControls';
import FieldLabel from '@components/FormFields/FieldLabel';
import CheckBox from '@components/FormFields/CheckBox';
import * as tsClient from '@tsClient';
import { useToast } from '@hooks';
import GenerateFieldWithAI from '@components/FormFields/GenerateFieldWithAI';
import useNoodleApi from '@hooks/useNoodleApi';
import Tooltip from '@components/Tooltip';
import WhatIs from '@components/WhatIs';
import StripeAccountSelector from '@components/StripeAccountSelector';
import AddStripeAccountModal from '@modals/AddStripeAccountModal';
import TeamsContext from '@providers/Teams/TeamsContext';
import { isAttorney } from '@helpers/businessCategories';
import ConfidoBankAccountSelector from '@components/ConfidoBankAccountSelector';
import s from './CreateEditPriceForm.module.scss';

const DURATION_OPTIONS = [
  { title: '30 minutes', value: '30' },
  { title: '60 minutes', value: '60' },
  { title: '90 minutes', value: '90' },
  { title: '120 minutes', value: '120' },
];

type ProductPriceFormProps = {
  currency: string;
  businessCategory: string;
  isSubmitting: boolean;
  price?: Pick<ApiModels.Price,
    | 'id'
    | 'isActive'
    | 'isDebitCardPaymentEnabled'
    | 'isCardPaymentEnabled'
    | 'isAchPaymentEnabled'
    | 'isExemptFromApplicationFee'
    | 'passProcessingFeesToCustomer'
    | 'numBookings'
    | 'price'
    | 'priceSubDescription'
    | 'priceTitle'
    | 'sessionDuration'
    | 'usageType'
    | 'secondaryStripeAccountId'
    | 'confidoBankAccountId'
  > & {
    priceDescription?: Pick<ApiModels.RichText, 'text'> | null;
  } | null;
  productTitle: string | null;
  productType: NoodleProductTypes;
  onSubmit: (
    data: {
      id?: string;
      isAchPaymentEnabled: boolean;
      isActive: boolean;
      isDebitCardPaymentEnabled: boolean;
      isCardPaymentEnabled: boolean;
      isExemptFromApplicationFee: boolean;
      passProcessingFeesToCustomer: boolean;
      priceAmount: number;
      priceDescription: string;
      priceDescriptionAIRequestId: string | null;
      priceSubDescription: string;
      priceTitle: string;
      priceTitleAIRequestId: string | null;
      selectedPriceUsageType: ApiModels.Price['usageType'];
      // @todo - all 3 or none...
      numBookings?: number | null;
      sessionDuration?: number | null;
      sessionInterval?: number | null;
      secondaryStripeAccountId?: string;
      confidoBankAccountId?: string;
    },
    isUpdate: boolean,
  ) => Promise<void>;
  isActiveByDefault: boolean;
  requiresPriceTitle: boolean;
};

const CreateEditPriceForm: FC<ProductPriceFormProps> = ({
  businessCategory,
  price,
  productType,
  productTitle,
  isSubmitting,
  onSubmit,
  isActiveByDefault = true,
  requiresPriceTitle = true,
  currency,
}) => {
  const addToast = useToast();
  const { creatorId } = useContext(TeamsContext);
  const [isAddStripeAccountModalOpen, setStripeAccountModalOpen] = useState(false);
  const [secondaryStripeAccountId, setSecondaryStripeAccountId] = useState<string | null>(price?.secondaryStripeAccountId || null);
  const [confidoBankAccountId, setConfidoBankAccountId] = useState<string | null>(price?.confidoBankAccountId || null);
  const [duration, setDuration] = useState<string>(price?.sessionDuration?.toString() || DURATION_OPTIONS[1].value);
  const [priceAmount, setPriceAmount] = useState(price?.price ?? 0);
  const [priceTitle, setPriceTitle] = useState<string>(price?.priceTitle || '');
  const [priceTitleAIRequestId, setPriceTitleAIRequestId] = useState<string | null>(null);
  const [priceDescription, setPriceDescription] = useState<string>(price?.priceDescription?.text || '');
  const [priceDescriptionAIRequestId, setPriceDescriptionAIRequestId] = useState<string | null>(null);
  const [selectedPriceUsageType, setSelectedPriceUsageType] = useState<ApiModels.Price['usageType']>(price?.usageType || StripePriceUsageType.OneTime);
  const [numBookings, setNumBookings] = useState<number | null>(price?.numBookings || null);
  const [subDescription, setSubDescription] = useState<string>(price?.priceSubDescription || '');
  const [isActive, setIsActive] = useState<boolean>(Boolean(price ? price?.isActive : isActiveByDefault));
  const [isExemptFromApplicationFee, setIsExemptFromApplicationFee] = useState<boolean>(price ? price.isExemptFromApplicationFee : false);
  const [requiresScheduling, setRequiresScheduling] = useState<boolean>(
    Boolean(price?.sessionDuration)
    || productType === NoodleProductTypes.InPersonSession
    || productType === NoodleProductTypes.Consultation,
  );
  const [isCardPayment, setIsCardPayment] = useState<boolean>(price
    ? price.isCardPaymentEnabled || Boolean(price.isDebitCardPaymentEnabled)
    : true);
  const [isDebitCardForced, setIsDebitCardForced] = useState<boolean>((price?.isDebitCardPaymentEnabled && !price?.isCardPaymentEnabled) || false);
  const [isAchPayment, setIsAchPayment] = useState<boolean>(price?.isAchPaymentEnabled ?? false);
  const [passProcessingFeesToCustomer, setPassProcessingFeesToCustomer] = useState<boolean>(price?.passProcessingFeesToCustomer ?? false);
  const isConsultationProduct = productType === NoodleProductTypes.Consultation;
  const isSessionProduct = productType === NoodleProductTypes.InPersonSession || productType === NoodleProductTypes.OnlineSession;
  const isChargeProductType = productType === NoodleProductTypes.Charge;

  const disabled = (requiresPriceTitle ? priceTitle === '' : false) || (isSessionProduct && (numBookings === null || numBookings === 0));
  const existingPrice = Boolean(price);
  const editExistingPrice = Boolean(price) && priceAmount === price?.price;
  const canExemptPriceFromApplicationFee = isAttorney(businessCategory);
  const canPassProcessingFeesToCustomer = creatorId === 'clrqnhnen1iji0blnhpyy4r62'; // only enable for Sanchez Garrison for now

  const { getData: generatePriceTitle } = useNoodleApi(tsClient.ai.generatePriceTitle);
  const { getData: generatePriceDescription } = useNoodleApi(tsClient.ai.generatePriceDescription);

  const handleSubmit = async (): Promise<void> => {
    const sessionData = isSessionProduct || isConsultationProduct
      ? {
        numBookings: numBookings ?? 1,
        sessionDuration: requiresScheduling ? Number(duration) : null,
        sessionInterval: 30,
      }
      : {};

    onSubmit(
      {
        confidoBankAccountId: confidoBankAccountId || undefined,
        id: price?.id,
        isAchPaymentEnabled: isAchPayment,
        isActive,
        isCardPaymentEnabled: isCardPayment && !isDebitCardForced,
        isDebitCardPaymentEnabled: isCardPayment,
        isExemptFromApplicationFee: canExemptPriceFromApplicationFee ? isExemptFromApplicationFee : false,
        passProcessingFeesToCustomer,
        priceAmount,
        priceDescription,
        priceDescriptionAIRequestId,
        priceSubDescription: subDescription,
        priceTitle: priceTitle || subDescription,
        priceTitleAIRequestId,
        secondaryStripeAccountId: secondaryStripeAccountId || undefined,
        selectedPriceUsageType,
        ...sessionData,
      },
      editExistingPrice,
    );
  };

  const handleStripeAccountChange = async (stripeAccountId: string | null): Promise<void> => {
    if (stripeAccountId === 'add-new-account') {
      setStripeAccountModalOpen(true);
    } else {
      setSecondaryStripeAccountId(stripeAccountId);
    }
  };

  const handleConfidoBankAccountChange = async (confidoBankId: string | null): Promise<void> => {
    setConfidoBankAccountId(confidoBankId);
  };

  const handlePriceTitleChange = (title: string, requestId: string | null): void => {
    if (requestId) {
      setPriceTitleAIRequestId(requestId);
    }
    setPriceTitle(title);
  };

  const handlePriceDescriptionChange = (description: string, requestId: string | null): void => {
    if (requestId) {
      setPriceDescriptionAIRequestId(requestId);
    }
    setPriceDescription(description);
  };

  const handleGeneratePriceTitle = async (): Promise<void> => {
    const response = await generatePriceTitle({
      businessCategory,
      isRecurring: selectedPriceUsageType === StripePriceUsageType.Recurring,
      numBookings,
      productTitle: productTitle || '',
      productType,
    });

    if (response.data) {
      handlePriceTitleChange(response.data.generatedText || '', response.data.id);
    } else if (response.error) {
      addToast(useToast.ToastTypeVariants.ERROR, response.error?.message || 'Failed to generate title');
    } else {
      handlePriceTitleChange('', null);
    }
  };

  const handleGeneratePriceDescription = async (): Promise<void> => {
    const response = await generatePriceDescription({
      businessCategory,
      duration: requiresScheduling ? Number(duration) : null,
      isRecurring: selectedPriceUsageType === StripePriceUsageType.Recurring,
      numBookings: (isSessionProduct || isConsultationProduct) ? numBookings ?? 1 : null,
      price: Number(priceAmount),
      priceTitle,
      productTitle: productTitle || '',
      productType,
    });

    if (response.data) {
      handlePriceDescriptionChange(response.data.generatedText || '', response.data.id);
    } else if (response.error) {
      addToast(useToast.ToastTypeVariants.ERROR, response.error?.message || 'Failed to generate description');
    } else {
      handlePriceDescriptionChange('', null);
    }
  };

  const handleChoosePriceUsageType = (type: StripePriceUsageType): void => {
    setSelectedPriceUsageType(type);
  };

  const handlePriceChange = (priceValue: number): void => {
    setPriceAmount(priceValue);
  };

  const handleIsPrivateToggle = (): void => {
    setIsActive(!isActive);
  };

  const handleRequiresScheduling = (): void => {
    setRequiresScheduling(prevValue => !prevValue);
  };

  const handleChangeDuration = (durationValue: string): void => {
    setDuration(durationValue);
  };

  const handleChangeIsExemptFromApplicationFee = (): void => {
    setIsExemptFromApplicationFee(val => !val);
  };

  return (
    <div>
      <WhatIs
        showQuestionIcon
        className={s.loom}
        title="See video on how it works"
        loom={
          (productType === NoodleProductTypes.InPersonSession && 'https://www.loom.com/share/f95b7ce121d5498f90234c737523e8d3')
          || (productType === NoodleProductTypes.Lite && 'https://www.loom.com/share/1e5419312d82462b8a89a332879fefa7')
          || (productType === NoodleProductTypes.OnlineSession && 'https://www.loom.com/share/cae420d86b3641babe230dc240cffe47')
          || undefined
        }
      />
      {!existingPrice && productType !== NoodleProductTypes.Consultation && (
        <>
          <div className={s.services}>
            <RadioInput
              inputName="price"
              inputs={[
                {
                  defaultChecked: true,
                  icon: Coin,
                  id: StripePriceUsageType.OneTime,
                  label: 'Single Payment',
                },
                {
                  icon: Coins,
                  id: StripePriceUsageType.Recurring,
                  label: 'Monthly Subscription',
                },
              ]}
              onChange={handleChoosePriceUsageType}
            />
          </div>
          <Spacer size={24} />
        </>
      )}
      <PriceInput
        currency={currency || Currency.Usd}
        value={priceAmount}
        name={'price'}
        onChange={handlePriceChange}
        isMonthly={selectedPriceUsageType === StripePriceUsageType.Recurring}
      />
      <Spacer size={24} />
      {isSessionProduct && (
        <>
          <InputNumberWithControls
            value={numBookings || 0}
            onChange={n => setNumBookings(n)}
            required
            label={`# of services included ${(selectedPriceUsageType === StripePriceUsageType.Recurring && 'per month') || ''}`}
          />
          {selectedPriceUsageType === StripePriceUsageType.Recurring && (
            <>
              <Spacer />
              <p className="caption">Contacts will automatically be asked to schedule their bookings with each monthly renewal.</p>
            </>
          )}
          <Spacer size={24} />
        </>
      )}
      {!isChargeProductType && (
        <div className={s.withTooltip}>
          <Tooltip alignment="right" id="priceTitle" text="Your price title helps differentiate between variations of your service" />
          <GenerateFieldWithAI
            id="priceTitle"
            value={priceTitle}
            name={'Price Title'}
            label={'Create price title'}
            isLargeField={false}
            onGenerateText={handleGeneratePriceTitle}
            onChange={handlePriceTitleChange}
          />
          <Spacer size={24} />
        </div>
      )}
      {!isChargeProductType && (
        <>
          {!isConsultationProduct && (
            <>
              <GenerateFieldWithAI
                id="priceDescription"
                value={priceDescription}
                name={'Price Description'}
                label={'What exactly do you get for this price? (Optional)'}
                isLargeField={true}
                onGenerateText={handleGeneratePriceDescription}
                onChange={handlePriceDescriptionChange}
              />
              <Spacer size={24} />
            </>
          )}
          {(isSessionProduct || isConsultationProduct) && (
            <>
              {productType === NoodleProductTypes.OnlineSession && (
                <div className={s.checkbox}>
                  <FieldLabel id="has-scheduling">Requires scheduling</FieldLabel>
                  <div>
                    <CheckBox
                      id={'has-scheduling'}
                      isSwitch
                      isSwitchGreenStyle
                      hasFixedHeight={false}
                      onChange={handleRequiresScheduling}
                      disabled={false}
                      values={{ requiresScheduling }}
                      name="requiresScheduling"
                      rules={{ isRequired: true }}
                    />
                  </div>
                </div>
              )}
              {requiresScheduling && (
                <>
                  <CustomSelect
                    id={'duration'}
                    label={'Duration per session'}
                    onChange={handleChangeDuration}
                    options={DURATION_OPTIONS}
                    value={duration}
                  />
                  <Spacer size={8} />
                  <p className="caption">
                    Scheduling will take place here on Noodle - no app required. You will set up your availability in the next step.
                  </p>
                  <Spacer size={24} />
                </>
              )}
            </>
          )}
          <div className={s.checkbox}>
            <FieldLabel id="is-active-price">Private Price</FieldLabel>
            <div>
              <CheckBox
                id={'is-active-price'}
                isSwitch
                isSwitchGreenStyle
                hasFixedHeight={false}
                onChange={handleIsPrivateToggle}
                disabled={false}
                values={{ isPrivate: !isActive }}
                name="isPrivate"
                rules={{ isRequired: true }}
              />
            </div>
          </div>
        </>
      )}
      {canPassProcessingFeesToCustomer && (
        <div className={s.checkbox}>
          <FieldLabel id='pass-processing-fees'>Pass payment processing fees to contact</FieldLabel>
          <div>
            <CheckBox
              id={'pass-processing-fees'}
              isSwitch
              isSwitchGreenStyle
              hasFixedHeight={false}
              onChange={() => {
                setPassProcessingFeesToCustomer(prev => !prev);
              }}
              disabled={false}
              values={{ passProcessingFeesToCustomer }}
              name='passProcessingFeesToCustomer'
              rules={{ isRequired: true }}
            />
          </div>
        </div>
      )}
      {!isConsultationProduct && (
        <>
          <div className={s.checkbox}>
            <FieldLabel id='is-ach-payment'>Allow ACH Payments</FieldLabel>
            <div>
              <CheckBox
                id={'is-ach-payment'}
                isSwitch
                isSwitchGreenStyle
                hasFixedHeight={false}
                onChange={() => {
                  setIsAchPayment(prev => !prev);
                }}
                disabled={false}
                values={{ isAchPaymentEnabled: isAchPayment }}
                name='isAchPaymentEnabled'
                rules={{ isRequired: true }}
              />
            </div>
          </div>
          <div className={s.checkbox}>
            <FieldLabel id='is-card-payment'>Allow Card Payments</FieldLabel>
            <div>
              <CheckBox
                id={'is-card-payment'}
                isSwitch
                isSwitchGreenStyle
                hasFixedHeight={false}
                onChange={() => {
                  setIsDebitCardForced(false);
                  setIsCardPayment(prev => !prev);
                }}
                disabled={false}
                values={{ isCardPaymentEnabled: isCardPayment }}
                name='isCardPaymentEnabled'
                rules={{ isRequired: true }}
              />
            </div>
          </div>
          {isCardPayment && (
            <div className={s.checkbox}>
              <FieldLabel id='is-debit-card-forced'>Force debit card transactions only</FieldLabel>
              <div>
                <CheckBox
                  id={'is-debit-card-forced'}
                  isSwitch
                  isSwitchGreenStyle
                  hasFixedHeight={false}
                  onChange={() => {
                    setIsDebitCardForced(prev => !prev);
                  }}
                  disabled={false}
                  values={{ isDebitCardForced }}
                  name='isDebitCardForced'
                  rules={{ isRequired: true }}
                />
              </div>
            </div>
          )}
        </>
      )}
      {canExemptPriceFromApplicationFee && (
        <>
          <div className={s.checkbox}>
            <FieldLabel id='is-exempt-from-application-fee'>
              Is Filing Fee.
              <br />
              <br />
              This price will go through a Noodle review for approval. It should only be used for filing fees.
            </FieldLabel>
            <div>
              <CheckBox
                id={'is-exempt-from-application-fee'}
                isSwitch
                isSwitchGreenStyle
                hasFixedHeight={false}
                onChange={handleChangeIsExemptFromApplicationFee}
                disabled={false}
                values={{ isExemptFromApplicationFee }}
                name='isExemptFromApplicationFee'
                rules={{ isRequired: true }}
              />
            </div>
          </div>
        </>
      )}

      {isChargeProductType && (
        <>
          <TextInput id="subDescription" value={subDescription} onChange={e => setSubDescription(e)} label="What's the payment for?" />
        </>
      )}

      <StripeAccountSelector value={secondaryStripeAccountId || undefined} onChange={handleStripeAccountChange} />
      <ConfidoBankAccountSelector value={confidoBankAccountId || undefined} onChange={handleConfidoBankAccountChange} />
      <Spacer size={12} />
      <Button size='lg' variant='primary' fullWidth onClick={handleSubmit} loading={isSubmitting} disabled={disabled} className={s.cta}>
        {existingPrice ? 'Update Price' : 'Create Price'}
      </Button>
      {isAddStripeAccountModalOpen && (
        <AddStripeAccountModal
          onClose={() => setStripeAccountModalOpen(false)}
          onCreateAccount={async (newId) => setSecondaryStripeAccountId(newId)}
        />
      )}
    </div>
  );
};

export default CreateEditPriceForm;
