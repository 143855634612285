import removeNullish from '@helpers/removeNullish';
import { logError } from '@providers/ErrorTracking';
import { Descendant } from 'slate';
import { jsx } from 'slate-hyperscript';

const deserializeHtmlInternal = (el: HTMLElement | ChildNode, markAttributes: Record<string, unknown> = {}): unknown => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  // define attributes for text nodes
  switch (el.nodeName.toLowerCase()) {
  case 'strong':
    nodeAttributes.bold = true;
    break;
  case 'b':
    nodeAttributes.bold = true;
    break;
  case 'em':
    nodeAttributes.italic = true;
    break;
  default:
  }

  const children = Array.from(el.childNodes)
    .map(node => deserializeHtmlInternal(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
  case 'BODY':
    return jsx('fragment', {}, children);
  case 'BR':
    return '\n';
  case 'BLOCKQUOTE':
    return jsx('element', { type: 'quote' }, children);
  case 'P':
    return jsx('element', { type: 'paragraph' }, children);
  case 'A':
    return jsx(
      'element',
      { type: 'link', url: (el as HTMLElement).getAttribute('href') },
      children,
    );
  case 'OL':
    return jsx('element', { type: 'numbered-list' }, children);
  case 'LI':
    return jsx('element', { type: 'list-item' }, children);
  default:
    return children;
  }
};

type AstNode =
  | { type: string; children: Descendant[] }
  | { text: 'string' }
  | null;

const deserializeHtml = (el: HTMLElement | ChildNode, markAttributes: Record<string, unknown> = {}): unknown => {

  const asSlate = deserializeHtmlInternal(el, markAttributes) as Array<AstNode>;

  return asSlate
    .map(node => {
      if (!node) {
        return node;
      }

      if (typeof node !== 'object') {
        logError('Unsupported node', { asSlate, node });
        return null;
      }

      // Can't have a text node at the top in the SlateEditor.
      // Wrap it in a paragraph instead.
      if ('text' in node) {
        return {
          children: [node],
          type: 'paragraph',
        };
      }

      return node;
    })
    .filter(removeNullish);
};

export default deserializeHtml;
