import { useState, forwardRef, PropsWithChildren, ForwardRefRenderFunction, useRef, ElementType, useEffect } from 'react';
import classnames from 'classnames';

import FieldHelpText from '@components/FieldHelpText';
import FieldLabel from '@components/FormFields/FieldLabel/FieldLabel';
import FieldError from '@components/FieldError/FieldError';

import { checkValidity, ValidationErrors } from '@helpers/validation';

import s from './InputField.module.scss';

type Props = {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  fieldWrapperClass?: string;
  formErrors?: ValidationErrors;
  hasFixedHeight?: boolean;
  helpText?: string;
  iconAfter?: React.ReactNode;
  iconBefore?: React.ReactNode;
  id: string;
  inputMode?: string;
  isFlat?: boolean;
  label?: string;
  name: string;
  onChange?: (arg0: string, arg1: object) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  rows?: number;
  rules?: object;
  style?: object;
  onAfterIconClick?: () => void;
  type?: string;
  values?: object;
  progress?: number;
  isTouched?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  isFullWidth?: boolean;
  autocomplete?: string;
};

const InputField: ForwardRefRenderFunction<HTMLInputElement | HTMLTextAreaElement, PropsWithChildren<Props>> = (
  {
    autoComplete = 'on',
    className,
    disabled,
    fieldWrapperClass,
    formErrors = {},
    hasFixedHeight = true,
    helpText,
    iconAfter,
    iconBefore,
    id,
    inputMode,
    isFlat,
    label,
    name,
    onChange,
    onFocus,
    onBlur,
    placeholder,
    rows,
    rules = {},
    style,
    onAfterIconClick,
    type,
    values,
    progress,
    isTouched: isTouchedExternal,
    autoFocus,
    required,
    isFullWidth,
    ...restProps
  },
  ref,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const cmpRef = ref || useRef();
  const [isTouchedInternal, setIsTouchedInternal] = useState(false);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>): void => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    const { value, name: field } = target;
    const { errorMessage } = checkValidity(value, rules[field as keyof typeof rules]);
    onChange?.(value, { formErrors: { ...formErrors, [field]: errorMessage } });
  };

  const handleBlur = (): void => {
    setIsTouchedInternal(true);
    if (onBlur) {
      onBlur();
    }
  };

  useEffect(() => {
    setIsTouchedInternal(false);
  }, [id]);

  const isTouched = isTouchedInternal || isTouchedExternal;
  const errorMessage = formErrors[name as keyof typeof formErrors];
  const showError = isTouched;
  const isValid = !errorMessage;

  const CMP = !rows || rows < 2 ? 'input' : ('textarea' as unknown as ElementType);
  return (
    <div className={classnames(s['input-field__wrapper'], {
      [s['input-field__wrapper--fixed-height']]: hasFixedHeight,
      [s['input-field__wrapper--full-width']]: isFullWidth,
    })}>
      {label && (
        <FieldLabel id={id} isValid={isValid || !showError}>
          {label}
        </FieldLabel>
      )}
      <div className={classnames(s['input-field__field-wrapper'], fieldWrapperClass)}>
        {iconBefore && <div className={classnames(s['input-field__icon'], s['input-field__icon--before'])}>{iconBefore}</div>}
        <CMP
          ref={cmpRef}
          name={name}
          id={id || name}
          value={values && values[name as keyof typeof values]}
          disabled={disabled}
          autoComplete={autoComplete}
          rows={rows}
          className={classnames(s['input-field__field'], className, {
            [s['input-field__field--is-flat']]: isFlat,
            [s['input-field__field--has-icon-after']]: iconAfter,
            [s['input-field__field--has-icon-before']]: iconBefore,
            [s['input-field__field--error']]: !isValid && showError && errorMessage,
          })}
          placeholder={placeholder}
          style={style}
          type={type || 'text'}
          {...restProps}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          inputMode={inputMode}
          autoFocus={autoFocus}
          required={required}
        />
        {iconAfter && (
          <button type='button' onClick={onAfterIconClick} className={classnames(s['input-field__icon'], s['input-field__icon--after'])}>
            {iconAfter}
          </button>
        )}
      </div>
      {progress !== undefined && progress > 0 && <progress value={progress} max="100" />}
      {progress !== undefined && progress > 0 && progress !== 100 && <p className='caption'><strong>Password strength:</strong> {errorMessage}</p>}
      {!isValid && errorMessage && showError && !(progress !== undefined && progress > 0) && <FieldError>{errorMessage}</FieldError>}
      {helpText && !(showError && errorMessage) && <FieldHelpText>{helpText}</FieldHelpText>}
    </div>
  );
};

export default forwardRef(InputField);
