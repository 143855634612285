type BusinessCategoryOption = {
  title: string;
  value: string;
  aiPrompt: string | null;
  flows: string[];
};

export const BUSINESS_CATEGORIES: Array<BusinessCategoryOption> = [
  { aiPrompt: 'Attorney', flows: ['attorney'], title: 'Attorney', value: 'attorney' },
  { aiPrompt: 'Attorney', flows: ['attorney'], title: 'Immigration Attorney', value: 'immigration-attorney' },
  { aiPrompt: 'Attorney', flows: ['attorney'], title: 'Bankruptcy Attorney', value: 'bankruptcy-attorney' },
  { aiPrompt: 'Golf Coach', flows: [], title: 'Golf Coach', value: 'golf-coach' },
  { aiPrompt: 'Fitness Coach', flows: [], title: 'Fitness Coach', value: 'fitness-coach' },
  { aiPrompt: 'Basketball Coach', flows: [], title: 'Basketball Coach', value: 'basketball-coach' },
  { aiPrompt: 'Life Coach', flows: [], title: 'Life Coach', value: 'life-coach' },
  { aiPrompt: 'Business Coach', flows: [], title: 'Business Coach', value: 'business-coach' },
  { aiPrompt: 'Physiotherapist', flows: [], title: 'Physiotherapy', value: 'physiotherapy' },
  { aiPrompt: 'Interior Designer', flows: [], title: 'Interior Design', value: 'interior-design' },
  { aiPrompt: 'Yogi', flows: [], title: 'Yoga', value: 'yoga' },
  { aiPrompt: 'Band / Musician', flows: [], title: 'Musician', value: 'musician' },
  { aiPrompt: 'Web designer', flows: [], title: 'Web designer', value: 'web-designer' },
  { aiPrompt: 'Graphic designer', flows: [], title: 'Graphic designer', value: 'graphic-designer' },
  { aiPrompt: 'Consultant', flows: [], title: 'Consultant', value: 'consultant' },
  { aiPrompt: 'Psychologist', flows: [], title: 'Psychologist', value: 'psychologist' },
  { aiPrompt: 'Handyman', flows: [], title: 'Handyman', value: 'handyman' },
  { aiPrompt: 'Tutor', flows: [], title: 'Tutor', value: 'tutor' },
  { aiPrompt: 'Carpenter', flows: [], title: 'Carpenter', value: 'carpenter' },
];

export const DEFAULT_BUSINESS_CATEGORY: BusinessCategoryOption = { aiPrompt: '', flows: [], title: '', value: '' };

export const isAttorney = (businessCategory?: string | null): boolean => {
  if (!businessCategory) {
    return false;
  }
  // @todo add something to the creator profile, in the meantime keep in sync with server
  return businessCategory?.toLowerCase?.()?.includes?.('attorney')
  || businessCategory?.toLowerCase?.()?.includes?.('lawyer')
  || false;
};

export const valueToAIPrompt = (value: string): string | null => {
  const obj = BUSINESS_CATEGORIES.find(opt => opt.value === value);
  return obj?.aiPrompt || value || null;
};

export const getDefaultBusinessCategoryForFlow = (flow: string | null): BusinessCategoryOption | undefined => {
  const businessCategoryByFlow = flow
    ? BUSINESS_CATEGORIES.find(category => category.flows.includes(flow))
    : null;

  return businessCategoryByFlow ?? undefined;
};
