import React, { FC, ReactElement, LegacyRef } from 'react';

import Play from '@components/Icons/Play';
import Pause from '@components/Icons/Pause';
import Buttons from '@components/Buttons';
import ProgressIndicator from '@components/ProgressIndicator';
import Shimmer from 'components/Shimmer';

import s from './AudioPlayer.module.scss';

export type AudioTemplateProps = {
  isLoaded?: boolean;
  isSmall?: boolean;
  remaining?: string;
  audioPlaying?: boolean;
  handlePlayPause?: () => void;
  audioRef?: LegacyRef<HTMLDivElement>;
};

const AudioPlayerTemplate: FC<AudioTemplateProps> = ({ audioRef, isLoaded, isSmall = false, remaining = '0:00', audioPlaying = false, handlePlayPause }): ReactElement => {
  const Icon = audioPlaying ? Pause : Play;
  return (
    <div className={s.audio}>
      {isLoaded
        ? null
        : (
          <div className={s.audioShimmer}>
            <Shimmer message="Loading Audio" rounded={true} />
          </div>
        )}
      <div className={s.audioContainer}>
        <Buttons
          isSecondary
          isLarge={!isSmall}
          ariaLabel="Play/Pause"
          {...handlePlayPause ? {onClick: handlePlayPause} : {}}
          disabled={!isLoaded}
          icon={
            !isLoaded
              ? (
                <ProgressIndicator />
              )
              : (
                <Icon weight="fill" size={isSmall ? 24 : 56} />
              )
          }
        />
        <div className={s.audioWave} ref={audioRef}></div>
        <div className={'caption'}>{remaining}</div>
      </div>
    </div>
  ); };

export default AudioPlayerTemplate;
