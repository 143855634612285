// @todo - when typescripting this consider exporting Descendent to simplify imports in consumers.
import React, { useCallback, useMemo, useEffect } from 'react';
import isHotkey from 'is-hotkey';
import NextImage from 'next/legacy/image';
import { Editable, withReact, useSlate, Slate, useSlateStatic, ReactEditor } from 'slate-react';
import { Editor, Transforms, createEditor, Element as SlateElement } from 'slate';
import { withHistory } from 'slate-history';

import ImageSquare from 'components/Icons/ImageSquare';
import VideoCamera from 'components/Icons/VideoCamera';
import Microphone from 'components/Icons/Microphone';
import TextBolder from 'components/Icons/TextBolder';
import TextItalic from 'components/Icons/TextItalic';
import ListBullets from 'components/Icons/ListBullets';
import ArrowsOut from 'components/Icons/ArrowsOut';
import Trash from 'components/Icons/Trash';
import Button from '@components/Buttons';
import { Toolbar } from './components';

export { default as slateHasText } from './slateHasText';
export { default as deserializeHtml } from './deserializeHtml';
export { default as slateSerialize } from './slateSerialize';
export { default as htmlTextToSlate } from './htmlTextToSlate';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
};

const LIST_TYPES = ['numbered-list'];

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const dummyFunction = () => {};

const SlateEditor = ({
  id,
  name,
  slateValue,
  onChange,
  isMediaButton = true,
  isArrowOut = false,
  containerClassName = '',
  placeholder,
  onFocus = dummyFunction,
}) => {
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <Slate editor={editor} value={slateValue} onChange={onChange}>
      <div className={containerClassName}>
        <Toolbar>
          <MarkButton format="bold" Icon={TextBolder} />
          <MarkButton format="italic" Icon={TextItalic} />
          <BlockButton format="numbered-list" Icon={ListBullets} />
          {isMediaButton && (
            <div style={{ display: 'flex' }}>
              <MediaButton id="add-image" accept="image/*" Icon={ImageSquare} />
              <MediaButton id="add-video" accept="video/*" Icon={VideoCamera} />
              <MediaButton id="add-audio" accept="audio/*" Icon={Microphone} />
            </div>
          )}
          {isArrowOut && (
            <div style={{ marginLeft: 'auto' }}>
              <MarkButton Icon={ArrowsOut} />
            </div>
          )}
        </Toolbar>

        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          name={name}
          placeholder={placeholder}
          id={id}
          style={{
            backgroundColor: 'var(--color-gray-0)',
            border: `1px solid var(--color-gray-25)`,
            borderRadius: '0px 0px 8px 8px',
            borderTop: 'none',
            boxSizing: 'border-box',
            maxHeight: '400px',
            minHeight: '100px',
            overflowY: 'auto',
            padding: '8px 16px', // Needed to change from margin to padding for EditChatPopUp, hopefully it doesn't break other things.
            width: '100%',
          }}
          onKeyDown={event => {
            Object.keys(HOTKEYS).forEach(hotkey => {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            });
          }}
          {...(onFocus && onFocus !== dummyFunction ? { onFocus } : {})}
        />
      </div>
    </Slate>
  );
};

const insertMedia = (editor, url, fileType) => {
  const text = { text: '' };
  const mediaFile = { children: [text], type: fileType, url };
  Transforms.insertNodes(editor, mediaFile);
};

const MediaButton = ({ id, accept, Icon }) => {
  const editor = useSlateStatic();

  const [file, setFile] = React.useState(null);

  useEffect(() => {
    if (file && /image/.test(file.type)) {
      insertMedia(editor, file.file, 'image');
    } else if (file && /video/.test(file.type)) {
      insertMedia(editor, file.file, 'video');
    } else if (file && /audio/.test(file.type)) {
      insertMedia(editor, file.file, 'audio');
    }
  }, [file, editor]);

  return (
    <label htmlFor={id}>
      <input
        id={id}
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        onChange={e => {
          setFile({
            file: URL.createObjectURL(e.target.files[0]),
            type: e.target.files[0].type,
          });
        }}
      />
      <Button isSmall icon={<Icon />} />
    </label>
  );
};

const Image = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} style={{ position: 'relative' }}>
        <NextImage
          alt=""
          src={element.url}
          style={{
            display: 'block',
            maxHeight: '20em',
            maxWidth: '100%',
          }}
        />
        <RemoveButton editor={editor} path={path} />
      </div>
    </div>
  );
};

const Video = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} style={{ position: 'relative' }}>
        <video
          style={{
            display: 'block',
            height: 'auto',
            width: '100%',
          }}
          controls
        >
          <source src={element.url} type="video/mp4" />
        </video>
        <RemoveButton editor={editor} path={path} />
      </div>
    </div>
  );
};

const Audio = ({ attributes, children, element }) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false} style={{ position: 'relative' }}>
        <audio controls>
          <source src={element.url} type="audio/mp3" />
        </audio>
        <RemoveButton editor={editor} path={path} />
      </div>
    </div>
  );
};

const RemoveButton = ({ editor, path }) => (
  <Button
    active
    onClick={() => Transforms.removeNodes(editor, { at: path })}
    style={{
      alignItems: 'center',
      backgroundColor: 'white',
      boxShadow: '0 0 0px 1px var(--color-gray-25)',
      cursor: 'pointer',
      display: 'flex',
      height: '28px',
      justifyContent: 'center',
      left: '10px',
      position: 'absolute',
      top: '-16px',
      width: '28px',
    }}
  >
    <Trash />
  </Button>
);

const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    }),
  );

  return !!match;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });
  const getType = () => {
    if (isActive) {
      return 'paragraph';
    }
    if (isList) {
      return 'list-item';
    }
    return format;
  };
  const newProperties = {
    type: getType(),
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { children: [], type: format };
    Transforms.wrapNodes(editor, block);
  }
};

const Element = props => {
  const { attributes, children, element } = props;

  switch (element.type) {
  case 'numbered-list':
    return <ol {...attributes}>{children}</ol>;
  case 'list-item':
    return <li {...attributes}>{children}</li>;
  case 'image':
    return <Image {...props} alt={props.alt} />;
  case 'video':
    return <Video {...props} />;
  case 'audio':
    return <Audio {...props} />;
  default:
    return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  let res = children;
  if (leaf.bold) {
    res = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    res = <em>{children}</em>;
  }

  return <span {...attributes}>{res}</span>;
};

const BlockButton = ({ format, Icon }) => {
  const editor = useSlate();
  return (
    <Button
      isSmall
      isGray={isBlockActive(editor, format)}
      onClick={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      icon={<Icon />}
    ></Button>
  );
};

const MarkButton = ({ format, Icon }) => {
  const editor = useSlate();
  return (
    <Button
      isSmall
      isGray={isMarkActive(editor, format)}
      onClick={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      icon={<Icon />}
    ></Button>
  );
};

export default SlateEditor;
