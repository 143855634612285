import React, { ReactElement } from 'react';
import RenderLoomVideo from '@components/Message/RenderLoomVideo';
import classNames from 'classnames';
import Question from '@components/Icons/Question';
import CollapsibleArea from '@components/CollapsibleArea';
import s from './WhatIs.module.scss';

const WhatIs = ({
  title,
  info,
  loom,
  className,
  showQuestionIcon,
}: {
  title: string;
  info?: string;
  loom?: string;
  className?: string;
  showQuestionIcon?: boolean;
}): ReactElement => {
  if (!info && !loom) return <></>;
  return (
    <CollapsibleArea
      title={title}
      Icon={showQuestionIcon ? { Component: Question, size: 16 } : undefined}
      className={classNames(s['whatIs-container'], className)}
    >
      {info && <p>{info}</p>}
      {loom && <RenderLoomVideo isFullWidth url={loom} />}
    </CollapsibleArea>
  );
};

export default WhatIs;
