import { ReactElement, useEffect, useState } from 'react';

import Modal from '@components/Modal';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { mixpanelTrack } from '@providers/Mixpanel';
import { Currency, Interval, NoodleProductTypes, StripePriceUsageType } from '@typings/graphql-models';
import createRichTextAst from '@helpers/createRichTextAst';
import CreateEditPriceForm from "@components/CreateEditPriceForm";
import s from './CreateEditPriceModal.module.scss';

type Props = {
  setIsVisible: (isOpen: boolean) => void;
  onPublish: (priceId?: string | null) => Promise<unknown>;
  businessCategory: string;
  defaultCurrency?: string | null;
  productId: string;
  price?: Parameters<typeof CreateEditPriceForm>[0]['price'];
  productTitle: string | null;
  productType: NoodleProductTypes;
  currency: string;
};

const CreateEditPriceModal = ({
  currency,
  setIsVisible,
  onPublish,
  businessCategory,
  productId,
  price,
  productTitle,
  productType,
  defaultCurrency,
}: Props): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getData: acceptGeneratedText } = useNoodleApi(tsClient.ai.acceptGeneratedText);
  const { getData: updatePriceFn } = useNoodleApi(tsClient.updatePrice, { toastOnError: true });
  const { getData: createPriceFn } = useNoodleApi(tsClient.createPrice, { toastOnError: true });

  const existingPrice = Boolean(price);

  const handleSubmit: Parameters<typeof CreateEditPriceForm>[0]['onSubmit'] = async (priceData, isUpdate) => {
    setIsSubmitting(true);

    const isRecurring = priceData.selectedPriceUsageType === StripePriceUsageType.Recurring;

    const commonCreateUpdateData = {
      confidoBankAccountId: priceData.confidoBankAccountId,
      isAchPaymentEnabled: priceData.isAchPaymentEnabled,
      isCardPaymentEnabled: priceData.isCardPaymentEnabled,
      isDebitCardPaymentEnabled: priceData.isDebitCardPaymentEnabled,
      isExemptFromApplicationFee: priceData.isExemptFromApplicationFee,
      numBookings: priceData.numBookings,
      passProcessingFeesToCustomer: priceData.passProcessingFeesToCustomer,
      priceTitle: priceData.priceTitle,
      secondaryStripeAccountId: priceData.secondaryStripeAccountId,
      sessionDuration: priceData.sessionDuration,
      sessionInterval: priceData.sessionInterval,
    };

    if (isUpdate && priceData?.id) {
      await updatePriceFn({
        body: {
          isActive: Boolean(priceData.isActive),
          priceDescription: createRichTextAst(priceData.priceDescription || ''),
          ...commonCreateUpdateData,
        },
        id: priceData.id,
      });
      onPublish(priceData.id);
    } else {
      const createdPrice = await createPriceFn({
        priceData: {
          currency: (defaultCurrency as Currency) || Currency.Usd,
          isActive: priceData.isActive,
          price: priceData.priceAmount,
          priceDescription: priceData.priceDescription,
          recurringInterval: isRecurring ? Interval.Month : undefined,
          usageType: priceData.selectedPriceUsageType,
          ...commonCreateUpdateData,
        },
        productId,
      });
      if (priceData?.id) {
        await updatePriceFn({
          body: { isActive: false },
          id: priceData.id,
        });
        onPublish();
      }
      if (createdPrice.data?.id && !priceData.id) {
        onPublish(createdPrice.data.id);
      }
    }

    await Promise.all([
      priceData.priceTitleAIRequestId
        ? acceptGeneratedText({
          requestId: priceData.priceTitleAIRequestId,
          usedText: priceData.priceTitle,
        })
        : null,
      priceData.priceDescriptionAIRequestId
        ? acceptGeneratedText({
          requestId: priceData.priceDescriptionAIRequestId,
          usedText: priceData.priceDescription || '',
        })
        : null,
    ]);
  };

  useEffect(() => {
    mixpanelTrack('Edit mode - create price');
  }, []);

  return (
    <Modal

      title={`${existingPrice ? 'Edit': 'Create'} Price${productTitle ? ` for ${productTitle}`: ''}`}
      onClose={() => {
        setIsVisible(false);
        window.scrollTo(0, 0);
      }}

    >
      <div className={s.createEditPriceModalPage}>
        <CreateEditPriceForm
          businessCategory={businessCategory}
          currency={currency}
          isActiveByDefault={true}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          price={price}
          productTitle={productTitle}
          productType={productType}
          requiresPriceTitle={true}
        />
      </div>
    </Modal>
  );
};

export default CreateEditPriceModal;
