import { Fragment, ReactElement } from 'react';
import classNames from 'classnames';
import Button from 'components/Buttons';

import { XCircle } from '@phosphor-icons/react';
import s from './FileUploadIndicator.module.scss';

type Props = {
  uploadPercentage: number;
  cancelUpload?: () => void;
  className?: string;
  showTop?: boolean;
};

const FileUploadIndicator = ({ uploadPercentage, cancelUpload, className, showTop = true }: Props): ReactElement => {
  const handleCancel = (): void => {
    cancelUpload?.();
  };

  return (
    <Fragment>
      <div className={classNames(s['percentage-indicator'], className)}>
        <div className={s['percentage-indicator__info']}>
          {showTop && (
            <div className={s['percentage-indicator__info-top']}>
              <span className={s['percentage-indicator__text']}>{uploadPercentage < 99 ? 'Uploading' : 'Processing...'}</span>
              {uploadPercentage !== -1 && <span className={s['percentage-indicator__value']}>{`${uploadPercentage}%`}</span>}
            </div>
          )}
          <progress max={100} className={s['percentage-indicator__progress']} value={uploadPercentage} />
        </div>
        {cancelUpload && (
          <Button
            className={s['percentage-indicator__cancel-btn']}
            onClick={handleCancel}
            isFlat
            icon={<XCircle weight="fill" size={16} color="var(--color-error)" />}
            title="Cancel uploading"
            aria-label="Cancel uploading"
          />
        )}
      </div>
    </Fragment>
  );
};

export default FileUploadIndicator;
