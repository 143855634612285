const Confido: React.FC = () => (
  <svg width="100" height="40" viewBox="0 0 100 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_0_3)">
      <path
        d="M12.475 8.725C12.6416 8.725 12.8083 8.725 12.975 8.725C17.5159 8.96216 21.0409 10.9622 23.55 14.725C23.8974 15.3031 24.2057 15.9031 24.475 16.525C24.448 16.5874 24.4146 16.6457 24.375 16.7C21.1518 17.9042 18.1018 17.5708 15.225 15.7C14.3727 14.9976 13.506 14.3143 12.625 13.65C9.71685 11.9628 6.71685 11.7795 3.625 13.1C3.39583 13.1467 3.32083 13.055 3.4 12.825C5.8661 10.2296 8.8911 8.86291 12.475 8.725Z"
        fill="#0182FF"
      />
      <path
        d="M71.025 8.875C72.115 8.85661 72.64 9.38995 72.6 10.475C72.3535 11.2778 71.812 11.6194 70.975 11.5C70.185 11.2459 69.86 10.7042 70 9.875C70.1715 9.36192 70.513 9.02859 71.025 8.875Z"
        fill="#0F1F33"
      />
      <path
        d="M66.375 9.075C67.1915 9.075 68.0085 9.075 68.825 9.075C68.825 9.625 68.825 10.175 68.825 10.725C68.0555 10.7032 67.289 10.7282 66.525 10.8C66.465 10.8451 66.4235 10.9035 66.4 10.975C66.375 11.6915 66.3665 12.4081 66.375 13.125C68.325 13.125 70.275 13.125 72.225 13.125C72.225 16.275 72.225 19.425 72.225 22.575C71.6085 22.575 70.9915 22.575 70.375 22.575C70.375 19.975 70.375 17.375 70.375 14.775C69.0415 14.775 67.7085 14.775 66.375 14.775C66.375 17.375 66.375 19.975 66.375 22.575C65.7415 22.575 65.1085 22.575 64.475 22.575C64.475 19.975 64.475 17.375 64.475 14.775C64.075 14.775 63.675 14.775 63.275 14.775C63.275 14.225 63.275 13.675 63.275 13.125C63.675 13.125 64.075 13.125 64.475 13.125C64.4665 12.3082 64.475 11.4915 64.5 10.675C64.547 10.1061 64.805 9.66439 65.275 9.35C65.6365 9.20966 66.003 9.11799 66.375 9.075Z"
        fill="#0F1F33"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.775 9.075H81.875C81.8835 10.7751 81.875 12.4751 81.85 14.175C80.7455 13.138 79.437 12.7297 77.925 12.95C76.473 13.0963 75.348 13.7713 74.55 14.975C73.8465 16.3177 73.6465 17.7344 73.95 19.225C74.3915 21.0667 75.5335 22.2084 77.375 22.65C79.141 23.0526 80.666 22.6276 81.95 21.375C81.975 21.7747 81.9835 22.1747 81.975 22.575H83.775V9.075ZM78.475 14.425C80.2515 14.2911 81.3765 15.0744 81.85 16.775C82.1335 18.0246 81.9165 19.1746 81.2 20.225C80.2 21.2551 79.025 21.5135 77.675 21C76.6035 20.4509 75.9785 19.5759 75.8 18.375C75.681 17.3352 75.914 16.3852 76.5 15.525C77.028 14.9155 77.686 14.5488 78.475 14.425Z"
        fill="#0F1F33"
      />
      <path
        d="M35.875 12.925C37.1552 12.7828 38.3719 12.9911 39.525 13.55C40.4567 14.122 41.0067 14.947 41.175 16.025C40.4914 16.0334 39.8081 16.025 39.125 16C38.7923 15.0912 38.1423 14.5745 37.175 14.45C35.5099 14.2947 34.4016 15.003 33.85 16.575C33.4876 17.8888 33.6876 19.1055 34.45 20.225C35.4823 21.2982 36.674 21.5232 38.025 20.9C38.5745 20.5079 38.9412 19.9829 39.125 19.325C39.8075 19.2751 40.4908 19.2583 41.175 19.275C40.856 21.1025 39.7894 22.2275 37.975 22.65C36.5452 22.9205 35.1785 22.7371 33.875 22.1C32.728 21.3681 32.0364 20.3264 31.8 18.975C31.4948 17.391 31.7948 15.941 32.7 14.625C33.5456 13.6564 34.6039 13.0897 35.875 12.925Z"
        fill="#0F1F33"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50.575 14C49.2865 13.0945 47.8532 12.7362 46.275 12.925C43.7169 13.2915 42.2752 14.7581 41.95 17.325C41.7785 19.1455 42.3868 20.6372 43.775 21.8C45.0917 22.6363 46.5251 22.9363 48.075 22.7C50.428 22.3303 51.803 20.972 52.2 18.625C52.4615 16.7919 51.9195 15.2503 50.575 14ZM46.625 14.425C48.9374 14.3956 50.1705 15.5373 50.325 17.85C50.3035 18.784 49.9953 19.609 49.4 20.325C48.3699 21.2428 47.1949 21.4844 45.875 21.05C44.6525 20.5097 43.9942 19.568 43.9 18.225C43.767 16.8501 44.242 15.7417 45.325 14.9C45.7374 14.6655 46.1707 14.5072 46.625 14.425Z"
        fill="#0F1F33"
      />
      <path
        d="M57.575 12.925C58.614 12.8134 59.614 12.9551 60.575 13.35C61.4245 13.8386 61.966 14.5636 62.2 15.525C62.2695 15.8218 62.3195 16.1218 62.35 16.425C62.375 18.475 62.3835 20.525 62.375 22.575C61.7585 22.575 61.1415 22.575 60.525 22.575C60.5335 20.7249 60.525 18.8749 60.5 17.025C60.5 16.4419 60.3665 15.8919 60.1 15.375C59.822 14.9483 59.4305 14.69 58.925 14.6C56.658 14.2345 55.4665 15.1928 55.35 17.475C55.325 19.1749 55.3165 20.8749 55.325 22.575C54.7085 22.575 54.0915 22.575 53.475 22.575C53.475 19.425 53.475 16.275 53.475 13.125C54.075 13.125 54.675 13.125 55.275 13.125C55.2665 13.5753 55.275 14.0253 55.3 14.475C55.8345 13.6286 56.593 13.112 57.575 12.925Z"
        fill="#0F1F33"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M95.325 18.175V17.525C95.14 15.148 93.89 13.6563 91.575 13.05C90.484 12.8583 89.401 12.8916 88.325 13.15C86.4825 13.7589 85.391 15.0173 85.05 16.925C84.7595 18.7528 85.2675 20.3111 86.575 21.6C87.9325 22.5951 89.449 22.9618 91.125 22.7C93.679 22.2547 95.079 20.7464 95.325 18.175ZM89.675 14.425C91.5505 14.2936 92.742 15.1269 93.25 16.925C93.5225 18.1638 93.2725 19.2805 92.5 20.275C91.428 21.2657 90.203 21.5073 88.825 21C87.948 20.6062 87.373 19.9479 87.1 19.025C86.765 17.7889 86.948 16.6389 87.65 15.575C88.1855 14.9367 88.8605 14.5534 89.675 14.425Z"
        fill="#0F1F33"
      />
      <path
        d="M-0.0250015 21.975C-0.0250015 21.8417 -0.0250015 21.7084 -0.0250015 21.575C0.222711 18.2705 1.87271 15.9788 4.925 14.7C7.8634 13.7676 10.48 14.3176 12.775 16.35C12.933 16.5165 13.0664 16.6998 13.175 16.9C13.1512 16.9882 13.1179 17.0715 13.075 17.15C11.3193 18.1854 10.4027 19.7271 10.325 21.775C10.3777 23.7898 11.2611 25.3148 12.975 26.35C13.1601 26.4766 13.2017 26.6349 13.1 26.825C11.3437 28.69 9.1854 29.4984 6.625 29.25C4.08348 28.8841 2.17515 27.5925 0.899998 25.375C0.34052 24.3032 0.0321865 23.1699 -0.0250015 21.975Z"
        fill="#0F1F33"
      />
      <path
        d="M19.675 26.125C21.2232 25.9998 22.7232 26.2081 24.175 26.75C24.2277 26.8194 24.236 26.8944 24.2 26.975C22.5177 30.2796 19.9094 32.5046 16.375 33.65C12.6855 34.642 9.2022 34.1587 5.925 32.2C5.4276 31.8721 4.95259 31.5137 4.5 31.125C4.46123 31.0026 4.5029 30.9192 4.625 30.875C7.27725 31.6868 9.79395 31.3785 12.175 29.95C13.2555 29.2195 14.3222 28.4695 15.375 27.7C16.6945 26.8479 18.1278 26.3229 19.675 26.125Z"
        fill="#0182FF"
      />
      <path
        d="M62.775 34.225C62.3915 34.225 62.0085 34.225 61.625 34.225C60.3255 34.0339 59.584 33.2839 59.4 31.975C59.222 30.8934 59.53 29.985 60.325 29.25C61.075 28.7776 61.892 28.6109 62.775 28.75C63.8665 28.9001 64.533 29.5085 64.775 30.575C64.3735 30.5915 63.9735 30.5749 63.575 30.525C63.233 29.8539 62.683 29.5789 61.925 29.7C60.988 29.9134 60.538 30.5051 60.575 31.475C60.647 32.8554 61.3635 33.4304 62.725 33.2C63.1885 33.0533 63.505 32.7533 63.675 32.3C63.1755 32.275 62.6755 32.2667 62.175 32.275C62.175 31.9583 62.175 31.6416 62.175 31.325C63.0585 31.325 63.9415 31.325 64.825 31.325C64.911 32.0943 64.736 32.7943 64.3 33.425C63.882 33.8713 63.374 34.138 62.775 34.225Z"
        fill="#0F1F33"
      />
      <path
        d="M45.175 28.775C45.5583 28.775 45.9416 28.775 46.325 28.775C46.325 30.2583 46.325 31.7416 46.325 33.225C47.1583 33.225 47.9916 33.225 48.825 33.225C48.825 33.5416 48.825 33.8583 48.825 34.175C47.6083 34.175 46.3916 34.175 45.175 34.175C45.175 32.375 45.175 30.575 45.175 28.775Z"
        fill="#0F1F33"
      />
      <path
        d="M52.025 28.775C53.375 28.775 54.725 28.775 56.075 28.775C56.075 29.0916 56.075 29.4083 56.075 29.725C55.1085 29.725 54.1415 29.725 53.175 29.725C53.175 30.1583 53.175 30.5916 53.175 31.025C54.0085 31.025 54.8415 31.025 55.675 31.025C55.675 31.3416 55.675 31.6583 55.675 31.975C54.8415 31.975 54.0085 31.975 53.175 31.975C53.175 32.3916 53.175 32.8083 53.175 33.225C54.175 33.225 55.175 33.225 56.175 33.225C56.175 33.5416 56.175 33.8583 56.175 34.175C54.7915 34.175 53.4085 34.175 52.025 34.175C52.025 32.375 52.025 30.575 52.025 28.775Z"
        fill="#0F1F33"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M71.525 28.825C71.1095 28.7751 70.693 28.7584 70.275 28.775C69.535 30.5612 68.785 32.3445 68.025 34.125C68.424 34.1832 68.824 34.1916 69.225 34.15C69.356 33.882 69.4725 33.607 69.575 33.325C70.4585 33.2583 71.3415 33.2583 72.225 33.325C72.347 33.5988 72.4635 33.8738 72.575 34.15C72.976 34.1916 73.376 34.1832 73.775 34.125L71.525 28.825ZM70.875 30.025C71.205 30.7731 71.522 31.5314 71.825 32.3C71.2085 32.3333 70.5915 32.3333 69.975 32.3C70.3095 31.5546 70.6095 30.7962 70.875 30.025Z"
        fill="#0F1F33"
      />
      <path
        d="M76.975 28.775C77.3415 28.775 77.7085 28.775 78.075 28.775C78.075 30.2583 78.075 31.7416 78.075 33.225C78.925 33.225 79.775 33.225 80.625 33.225C80.625 33.5416 80.625 33.8583 80.625 34.175C79.4085 34.175 78.1915 34.175 76.975 34.175C76.975 32.375 76.975 30.575 76.975 28.775Z"
        fill="#0F1F33"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_3">
        <rect width="100" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Confido;
