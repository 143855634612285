import { useCallback, useEffect, useRef, useState } from 'react';
import InputField from '../InputField';
import validate from './validate';

type Props = {
  id: string;
  disabled?: boolean;
  value: string;
  placeholder?: string;
  onChange: (newValue: string) => void;
  onError?: (error: string | null) => void;
  required?: boolean;
  label?: string;
  error?: string | null;
  isTouched?: boolean;
  autoComplete?: string;
  hasFixedHeight?: boolean;
  rows?: number;
  isFullWidth?: boolean;
  style?: object;
};

const TextInput: React.FC<Props> = ({
  id,
  disabled,
  placeholder = '',
  value,
  onChange,
  onError,
  required = false,
  label,
  error,
  isTouched,
  autoComplete,
  hasFixedHeight,
  rows = 1,
  isFullWidth,
  style,
}) => {
  const [internalError, setInternalError] = useState<string | null>('');

  // Want to always use the latest without needing to put in useCallback/useEffect dependency lists
  const onChangeRef = useRef<Props['onChange']>();
  onChangeRef.current = onChange;
  const onErrorRef = useRef<Exclude<Props['onError'], undefined> | null>();
  onErrorRef.current = onError;

  const handleOnChange = useCallback((newValue: string) => {
    if (onChangeRef.current) {
      onChangeRef.current(newValue);
    }
  }, []);

  useEffect(() => {
    const newError = validate(value, { isRequired: required });
    setInternalError(newError);
    if (onErrorRef.current) {
      onErrorRef.current(newError);
    }
  }, [value, required]);

  const name = id;

  return (
    <InputField
      id={id}
      disabled={disabled}
      name={name}
      values={{ [name]: value }}
      formErrors={{ [name]: error || internalError }}
      onChange={handleOnChange}
      placeholder={placeholder}
      type={'text'}
      label={label}
      isTouched={isTouched}
      autoComplete={autoComplete}
      hasFixedHeight={hasFixedHeight}
      rows={rows}
      isFullWidth={isFullWidth}
      style={style}
    />
  );
};

export default TextInput;
