import { FC, ReactElement, useState } from 'react';
import { m } from 'framer-motion';
import Info from '@components/Icons/Info';
import classNames from 'classnames';
import s from './Tooltip.module.scss';

const Tooltip: FC<{ text: string; id?: string; alignment?: 'left' | 'center' | 'right'; children?: ReactElement; className?: string }> = ({
  text,
  id,
  alignment,
  children,
  className,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  return (
    <div
      className={classNames(s.tooltipWrapper, className)}
      style={{ alignItems: (alignment === 'left' && 'flex-start') || (alignment === 'right' && 'flex-end') || 'center' }}
    >
      <div
        onClick={() => setIsTooltipVisible(!isTooltipVisible)}
        onFocus={() => setIsTooltipVisible(true)}
        onBlur={() => setIsTooltipVisible(false)}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        className={s.trigger}
        {...(!children && {
          style: {
            height: 44,
            width: 44,
          },
        })}
      >
        {children || <Info weight="fill" size={20} />}
      </div>
      <m.div
        role="tooltip"
        id={(id && `${id}-describer`) || undefined}
        initial={{ opacity: 0, y: 8 }}
        animate={{ opacity: isTooltipVisible ? 1 : 0, y: isTooltipVisible ? 8 : 16 }}
        transition={{ duration: 0.2 }}
        className={(alignment === 'left' && s.tooltipLeft) || (alignment === 'right' && s.tooltipRight) || s.tooltip}
      >
        {text}
      </m.div>
    </div>
  );
};

export default Tooltip;
