import { useEffect } from 'react';

const BeforeUnload = ():null => {
  useEffect(() => {
    const handlePreventReload = (ev: BeforeUnloadEvent): BeforeUnloadEvent => {
      ev.preventDefault();
      // eslint-disable-next-line no-param-reassign
      ev.returnValue = 'Are you sure you want to close?';
      return ev;
    };
    window.addEventListener("beforeunload", handlePreventReload);

    return () => window.removeEventListener("beforeunload", handlePreventReload);
  }, []);
  return null;
};

export default BeforeUnload;