import { BaseElement, BaseText, Descendant } from 'slate';

const findText = (node: Descendant): boolean => {
  if ((node as BaseText)?.text?.trim()) {
    return true;
  }
  if ((node as BaseElement)?.children) {
    for (let i = 0; i < (node as BaseElement).children.length; i += 1) {
      const nodeHasText = findText((node as BaseElement).children[i]);
      if (nodeHasText) {
        return true;
      }
    }
  }
  return false;
};

const slateHasText = (value: { children: Descendant[] }[]): boolean => {
  for (let i = 0; i < value.length; i += 1) {
    const nodeHasText = findText(value[i]);
    if (nodeHasText) {
      return true;
    }
  }
  return false;
};

export default slateHasText;
