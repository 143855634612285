import classNames from 'classnames';
import type { Icon } from '@phosphor-icons/react';
import { useState, useRef, useEffect } from 'react';
import CaretCircleDown from '@components/Icons/CaretCircleDown';
import s from './CollapsibleArea.module.scss';

type Props = {
  title: string;
  openTitle?: string;
  className?: string;
  Icon?: {
    Component: Icon;
    color?: string;
    size?: number;
  };
  caretPosition?: 'left' | 'right';
  open?: boolean;
  description?: string;
};

const CollapsibleArea: React.FC<Props> = ({ caretPosition = 'right', children, className, description, open, title, openTitle, Icon }) => {
  const [isOpen, setIsOpen] = useState(open);
  const detailsRef = useRef<HTMLDetailsElement>(null);
  useEffect(() => {
    detailsRef.current?.addEventListener('toggle', () => {
      setIsOpen(detailsRef.current?.open);
    });
  }, [setIsOpen]);
  return (
    <details ref={detailsRef} className={classNames(s['collapsible-area'], className)} open={isOpen}>
      <summary onClick={(e) => e.stopPropagation()}>
        {caretPosition === 'left' && <CaretCircleDown weight='fill' size={16} />}
        <div>
          {Icon && <Icon.Component size={Icon.size || 20} color={Icon.color || 'var(--color-gray-100)'} />}
          <div className={s.titleAndDescription}>
            <span>{(isOpen && openTitle) ? openTitle : title}</span>
            {description && <span className='caption'>{description}</span>}
          </div>
        </div>
        {caretPosition === 'right' && <CaretCircleDown weight='fill' size={16} />}
      </summary>
      {children}
    </details>
  );
};

export default CollapsibleArea;
