import { FC, useContext, useState } from 'react';
import Modal from '@components/Modal';
import InputField from '@components/FormFields/InputField/InputField';
import Button from '@components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import teamsContext from '@providers/Teams/TeamsContext';
import s from './AddStripeAccountModal.module.scss';

type Props = {
  onClose: () => void;
  onCreateAccount?: (id: string) => Promise<void>;
};

const AddStripeAccountModal: FC<Props> = ({ onClose, onCreateAccount }) => {
  const { creatorId } = useContext(teamsContext);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [accountName, setAccountName] = useState('');

  const { getData: createStripeAccountFn } = useNoodleApi(tsClient.createSecondaryStripeAccount);

  const createAccount = async (): Promise<void> => {
    if (creatorId) {
      setIsCreatingAccount(true);
      const { data } = await createStripeAccountFn({ creatorId, name: accountName });
      if (onCreateAccount && data) {
        await onCreateAccount(data.stripeAccountId);
      }
      if (data?.redirectUrl) {
        window.open(data.redirectUrl, '_blank');
      }
      onClose();
    }
    setIsCreatingAccount(false);
  };

  return (
    <Modal
      hideBottomInset

      title='Add bank account'
      onClose={onClose}

    >
      <div className={s.container}>
        <InputField
          id='accountName'
          name='accountName'
          label='Account Nickname'
          onChange={(val) => setAccountName(val)}
          values={{ accountName }}
        />
        <Spacer size='24px' />
        <p className='caption'>
          You’ll be sent to create a new, separate account on Stripe. All payments collected for this product will be sent to this new account.
          You can view and manage your accounts from the Payouts tab in the Noodle dashboard.
        </p>
        <Spacer size='12px' />
        <Button
          loading={isCreatingAccount}
          disabled={!accountName}
          variant='primary'
          size='md'
          fullWidth
          onClick={createAccount}
        >
          Create Account
        </Button>
      </div>
    </Modal>
  );
};

export default AddStripeAccountModal;
