import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Toolbar = styled.div`
  border: 1px solid var(--color-gray-25);
  background-color: var(--color-gray-0);
  border-radius: 8px 8px 0px 0px;
  border-bottom: none;
  margin-bottom: -16px;
  padding: 8px;
  display: flex;
  gap: 2px;
`;
