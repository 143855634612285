import React, { useEffect, useState, useRef, ReactElement, useCallback, MouseEvent } from 'react';
import classNames from 'classnames';
import s from './TextEllipsis.module.scss';

type Props = {
  children: React.ReactNode;
  lines?: number;
  isNotExpandable?: boolean;
  isButtonString?: boolean;
  className?: string;
  buttonClassName?: string;
};

function TextEllipsis({ children, lines = 1, isNotExpandable = false, isButtonString = false, className, buttonClassName }: Props): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const elRef = useRef<HTMLDivElement>(null);

  const toggleViewMore = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded(prevState => !prevState);
  };

  const checkCanBeExpanded = useCallback((): void => {
    if (isNotExpandable) {
      setIsExpandable(false);
      return;
    }

    const element = elRef.current;
    if (!element) return;

    const clone = element.cloneNode(true) as HTMLDivElement;
    clone.style.position = 'absolute';
    clone.style.visibility = 'hidden';
    clone.style.height = 'auto';
    clone.style.webkitLineClamp = 'unset';
    clone.style.display = '-webkit-box';
    clone.style.webkitBoxOrient = 'vertical';
    clone.style.overflow = 'hidden';

    element.parentNode?.appendChild(clone);

    requestAnimationFrame(() => {
      setIsExpandable(clone.clientHeight > element.clientHeight);
      clone.remove();
    });
  }, [isNotExpandable]);

  useEffect(() => {
    const handleResize = (): void => checkCanBeExpanded();

    const element = elRef.current;
    if (element) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, [checkCanBeExpanded]);

  const elementStyle: React.CSSProperties = isExpanded
    ? { height: 'auto' }
    : {
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: `${lines}`,
      display: '-webkit-box',
      overflow: 'hidden',
    };

  return (
    <div>
      <div
        ref={elRef}
        className={classNames(s['text-ellipsis'], className, {
          [s['text-ellipsis--expanded']]: isExpanded,
        })}
        style={elementStyle}
      >
        {children}
      </div>
      {isExpandable && (
        <button
          className={classNames(s['text-ellipsis__button'], { [s['text-ellipsis__button--string']]: isButtonString }, buttonClassName)}
          onClick={toggleViewMore}
        >
          {isExpanded ? 'Collapse' : 'Read More'}
        </button>
      )}
    </div>
  );
}

export default TextEllipsis;
