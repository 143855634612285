import FieldLabel from '@components/FormFields/FieldLabel';
import clsx from 'classnames';
import * as format from '@format';
import { useEffect, useRef, useState } from 'react';
import s from './PriceInput.module.scss';

type Props = {
  className?: string;
  currency: string;
  isMonthly?: boolean;
  isYearly?: boolean;
  label?: string;
  max?: number;
  min?: number;
  name: string;
  disabled?: boolean;
} & (
  | {
    isNullable?: false;
    onChange: (val: number) => void;
    value: number;
  }
  | {
    isNullable: true;
    onChange: (val: number | null) => void;
    value: number | null;
  }
);

const applyMinMax = ({ value, min, max }: { value: number; min: number; max: number }): number => {
  if (value === 0) {
    return 0;
  }
  if (value > max) {
    return max;
  }
  if (value < min) {
    return min;
  }
  return value;
};

const numberFormat = (asString: string): string => {
  if (asString === '') {
    return '';
  }

  let string = asString;

  if (string.indexOf('.') === -1 && string.length > 1 && string.charAt(0) === '0') {
    string = string.slice(1);
  }

  if (asString.indexOf('.') > -1) {
    string = `${string.slice(0, asString.indexOf('.'))}.${string.slice(string.indexOf('.') + 1, string.indexOf('.') + 3)}`;
  }

  return string;
};

const PriceInput: React.FC<Props> = ({
  className = '',
  currency,
  isMonthly,
  isYearly,
  label,
  max = 999999.99,
  min = 0.0,
  name,
  onChange,
  value,
  isNullable,
  disabled,
}) => {
  const [valueAsString, setValueAsString] = useState(value === null ? '' : value.toString());
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const amountAsNumber = valueAsString === '' ? null : Number(valueAsString);

  useEffect(() => {
    if (isNullable) {
      if (amountAsNumber !== null) {
        if (!Number.isNaN(amountAsNumber)) {
          const newValue = applyMinMax({ max, min, value: amountAsNumber ?? 0 });
          onChangeRef.current(newValue);
          setValueAsString(newValue.toString());
        }
      }
      else {
        // as is valid becuase of isNullable guard above.
        (onChangeRef.current as (val: number | null) => void)(null);
        setValueAsString('');
      }
    } else if (!Number.isNaN(amountAsNumber)) {
      const newValue = applyMinMax({ max, min, value: amountAsNumber ?? 0 });
      onChangeRef.current(newValue);
      setValueAsString(newValue.toString());
    }
  }, [amountAsNumber, isNullable, min, max]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    // allow only numbers and dot
    const regex = /[^0-9.]/g;
    let inputValue = event.target.value.replace(regex, '');

    // allow only one dot
    if (inputValue.indexOf('.') > -1) {
      const firstDot = inputValue.indexOf('.');
      const lastDot = inputValue.lastIndexOf('.');
      if (firstDot !== lastDot) {
        inputValue = inputValue.slice(0, lastDot);
      }
    }
    // const inputAsNumber = applyMinMax({ value: parseFloat(inputValue), min, max });
    // setValueAsString(numberFormat(inputAsNumber.toString()));
    setValueAsString(numberFormat(inputValue));
  };

  const getTitle = (): string => {
    if (label) {
      return label;
    }
    if (isMonthly) {
      return 'Monthly price *';
    } if (isYearly) {
      return 'Yearly Price *';
    }
    return 'Price *';
  };

  return (
    <div className={s['price-input']}>
      <div className={s['price-input__header']}>
        <FieldLabel id={name}>{getTitle()}</FieldLabel>
        {amountAsNumber !== null && <b style={disabled ? { opacity: 0.5 } : undefined}>{`${format.price.withCurrency(amountAsNumber, currency)}`}</b>}
      </div>
      <input
        className={clsx(s['price-input__input'], className)}
        type="string"
        inputMode="decimal"
        id={name}
        onChange={handleChange}
        min={min}
        max={max}
        value={valueAsString}
        disabled={disabled}
      />
    </div>
  );
};

export default PriceInput;
