/* eslint-disable import/prefer-default-export */

export const KEY_CODES = {
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  BACK_SPACE: 8,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
};

export const YOUTUBE_ID_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/;

export const SPOTIFY_ID_REGEX = /^(?:https?:\/\/)?(?:www\.)?open\.spotify\.com\/(?:embed\/)?track\/((\w|-){22})(\?\S*)?$/;

export const URL_REGEX = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-;]*[\w@?^=%&/~+#-])/g;
