import * as validation from '../validate';

type Options = {
  isRequired: boolean;
};

const myValidate = (_value: string): string | null => null;
const validate = (value: string, options: Options): string | null => (
  (options.isRequired && validation.isRequired(value))
  || myValidate(value)
);

export default validate;
