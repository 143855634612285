import { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import FieldLabel from '@components/FormFields/FieldLabel';
import NoodleAI from '@components/Icons/NoodleAI';
import ArrowClockwise from '@components/Icons/ArrowClockwise';
import Shimmer from '@components/Shimmer';
import * as validate from '../validate';
import s from './GenerateFieldWithAI.module.scss';

type Props = {
  id: string;
  value: string;
  name: string;
  label: string;
  error?: string | null;
  isLargeField: boolean;
  onGenerateText: () => void;
  onChange: (text: string, requestId: string | null) => void;
  required?: boolean;
  onError?: (error: string | null) => void;
  isTouched?: boolean;
  placeholder?: string;
};

const GenerateFieldWithAI: React.FC<Props> = ({
  id,
  onChange,
  onGenerateText,
  value,
  name,
  label,
  isLargeField,
  isTouched: isTouchedExternal,
  error: externalError,
  onError,
  required,
  placeholder,
}) => {
  const [isTouchedInternal, setIsTouchedInternal] = useState(false);
  const [hasGeneratedText, setHasGeneratedText] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [internalError, setInternalError] = useState<string | null>('Required.');
  const error = externalError || internalError;

  // Want to always use the latest without needing to put in useCallback/useEffect dependency lists
  const onChangeRef = useRef<Props['onChange']>();
  onChangeRef.current = onChange;
  const onErrorRef = useRef<Exclude<Props['onError'], undefined> | null>();
  onErrorRef.current = onError;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const animateGeneration = useCallback((text: string, index: number) => {
    if (inputRef.current) {
      inputRef.current.value = text.substring(0, index);
      if (index < text.length) {
        // take pauses for commas and periods
        const pause = text[index] === ',' || text[index] === '.' ? 100 : 30;
        setTimeout(() => {
          animateGeneration(text, index + 1);
        }, pause);
      } else {
        setIsAnimating(false);
      }
    }
  }, []);

  useEffect(() => {
    if (hasGeneratedText) {
      animateGeneration(value, 0);
      setIsAnimating(true);
    }
  }, [animateGeneration, hasGeneratedText, value]);

  useEffect(() => {
    const newError = required ? validate.isRequired(value) : null;
    setInternalError(newError);
    if (onErrorRef.current) {
      onErrorRef.current(newError);
    }
  }, [value, required]);

  const handleGenerateText = async (): Promise<void> => {
    onChange('', null);

    setIsGenerating(true);
    setIsTouchedInternal(true);
    setHasGeneratedText(true);

    onGenerateText();

    setIsGenerating(false);
  };

  const handleTyping: ChangeEventHandler<HTMLTextAreaElement> = event => {
    setIsTouchedInternal(true);
    setHasGeneratedText(false);
    onChange(event.target.value, null);
  };

  const isValid = !error;
  const buttonText = hasGeneratedText ? 'Regenerate' : `Generate ${name}`;
  const isShimmering = isGenerating || isAnimating;
  const disabled = isShimmering;
  const isTouched = (isTouchedInternal || isTouchedExternal) && !isShimmering;

  return (
    <div className={classnames(s['generate-field-with-ai__wrapper'])}>
      <FieldLabel id={id} isValid={isValid || !isTouched}>
        {label}
      </FieldLabel>
      <textarea
        ref={inputRef}
        className={classnames(s['generate-field-with-ai__field'])}
        placeholder={placeholder || `Enter ${name}`}
        rows={isLargeField ? 5 : 1}
        onChange={handleTyping}
        disabled={isShimmering}
        value={isShimmering ? '' : value}
        aria-describedby={`${id}-describer`}
      />
      <button className={classnames(s['generate-field-with-ai__button'])} onClick={handleGenerateText} disabled={disabled}>
        {isShimmering
          ? (
            <Shimmer isSmall message={'Working something up...'} />
          )
          : (
            <>
              {hasGeneratedText ? <ArrowClockwise size={20} /> : <NoodleAI size={20} />}
              {buttonText}
            </>
          )}
      </button>
    </div>
  );
};

export default GenerateFieldWithAI;
