import classNames from 'classnames';
import React, { useState, forwardRef, Fragment, ForwardedRef, ReactElement, ChangeEventHandler } from 'react';

import FieldHelpText from 'components/FieldHelpText';
import FieldLabel from 'components/FormFields/FieldLabel/FieldLabel';
import FieldError from 'components/FieldError/FieldError';

import Check from 'components/Icons/Check';
import CheckSquare from 'components/Icons/CheckSquare';
import Square from 'components/Icons/Square';

import { checkValidity } from 'helpers/validation';

import s from './CheckBox.module.scss';

type Props = {
  id: string;
  name: string;
  label?: string | ReactElement;
  values: Record<string, string | boolean>;
  onChange?: (arg0: string, arg1: object) => void;
  disabled?: boolean;
  helpText?: string;
  rules?: object;
  formErrors?: Record<string, string>;
  isSwitch?: boolean;
  isDay?: boolean;
  isSwitchGreenStyle?: boolean;
  hasFixedHeight?: boolean;
  noFlex?: boolean;
  color?: string;
  className?: string;
};

// eslint-disable-next-line react/display-name
const CheckBox = forwardRef(
  (
    {
      className,
      id,
      name,
      label,
      values,
      onChange,
      disabled,
      helpText,
      rules = {},
      formErrors = {},
      isSwitch = false,
      isDay = false,
      isSwitchGreenStyle = false,
      hasFixedHeight = true,
      noFlex,
      color,
      ...restProps
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [isTouched, setIsTouched] = useState(false);

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (e): void => {
      const { target } = e;
      const { value, name: field } = target;
      const { errorMessage } = checkValidity(value, rules[field as keyof typeof rules]);
      onChange?.(value, { formErrors: { ...formErrors, [field]: errorMessage } });
    };
    const errorMessage = formErrors[name as keyof typeof formErrors];
    const isValid = !errorMessage;

    return (
      <div
        onClick={e => e.stopPropagation()}
        className={classNames(
          className,
          s['custom-checkbox__wrapper'],
          hasFixedHeight && s['custom-checkbox__wrapper--fixed-height'],
          disabled && s['custom-checkbox__wrapper--disabled'],
        )}
        style={{
          flex: !noFlex ? 1 : undefined,
        }}
      >
        <FieldLabel id={id || name} isValid={isValid || !isTouched}>
          <div
            className={classNames(
              s['custom-checkbox__field'],
              isSwitch && s['custom-checkbox__field--switch'],
              ((!isValid && errorMessage && isTouched) || helpText) && s['custom-checkbox__field--help-text'],
              values?.[name] && s['custom-checkbox__field--checked'],
            )}
          >
            {isSwitch && (
              <div
                className={classNames(s['custom-checkbox__switch'], {
                  [s['custom-checkbox__switch--checked-green']]: values?.[name] && isSwitchGreenStyle,
                  [s['custom-checkbox__switch--checked']]: values?.[name] && !isSwitchGreenStyle,
                })}
              >
                <div className={s['custom-checkbox__switch-point']} />
              </div>
            )}
            {isDay && (
              <div
                className={classNames(s['custom-checkbox__day'], {
                  [s['custom-checkbox__day--checked']]: values?.[name],
                })}
              >
                <p className={classNames(s['custom-checkbox__day-text'], 'caption')}>{name.slice(0, 2)}</p>
                <div className={s['custom-checkbox__day-checkBackground']} />
                {values?.[name] && (
                  <div className={s['custom-checkbox__day-checkIcon']}>
                    <Check weight="bold" size={13} />
                  </div>
                )}
              </div>
            )}
            {!isSwitch && !isDay && <Fragment>{values?.[name] ? <CheckSquare size={19} color={color} weight="fill" /> : <Square size={19} />}</Fragment>}
            {label}
            <input
              ref={ref}
              name={name}
              type="checkbox"
              id={id || name}
              disabled={disabled}
              value={values?.[name] as string}
              onChange={onInputChange}
              onBlur={() => setIsTouched(true)}
              className={s['custom-checkbox__input']}
              {...restProps}
            />
          </div>
        </FieldLabel>

        {!isValid && errorMessage && isTouched && <FieldError>{errorMessage}</FieldError>}
        {helpText && !(isTouched && errorMessage) && <FieldHelpText>{helpText}</FieldHelpText>}
      </div>
    );
  },
);

export default CheckBox;
