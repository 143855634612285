import escapeHtml from 'escape-html';
import { Text as TextSlate } from 'slate';

const slateSerialize = (node) => {
  if (TextSlate.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    return string;
  }

  const childrenAsText = node.children.map((n) => slateSerialize(n)).join('');

  switch (node.type) {
  case 'paragraph':
    return `<p>${childrenAsText}</p>`;
  case 'numbered-list':
    return `<ol>${childrenAsText}</ol>`;
  case 'list-item':
    return `<li>${childrenAsText}</li>`;
  default:
    return childrenAsText;
  }
};

export default slateSerialize;
