import { NOODLE_MEDIA_HOST, FEATURE_MULTI_REGION_MEDIA } from '@configuration/client';
import { Media } from '@typings/graphql-models';

const throughServer = (media: Pick<Media, 's3OriginId'>): string => {
  const directUrl = new URL(NOODLE_MEDIA_HOST);
  directUrl.pathname = `/file/${media.s3OriginId}`;
  return directUrl.toString();
};

const getMediaUrl = (media: Pick<Media, 'url' | 's3OriginId'>): string => {
  if (!FEATURE_MULTI_REGION_MEDIA || !media.url) {
    return throughServer(media);
  }
  return media.url;
};

export default getMediaUrl;
