import { ReactElement, useState } from 'react';
import type { Icon } from '@phosphor-icons/react';
import classNames from 'classnames';
import { m } from 'framer-motion';
import { NoodleProductTypes } from '@typings/graphql-models';
import Collection from '@components/Icons/Collection/Collection';
import s from './RadioInputCards.module.scss';

type RadioInputCardsProps<INPUT_ID_TYPE extends string> = {
  legend?: string;
  inputName: string;
  inputs: Array<{
    id: INPUT_ID_TYPE;
    label: string;
    icon?: Icon | null;
    defaultChecked?: boolean;
    description?: string;
    isDisabled?: boolean;
    extraDescription?: string;
  }>;
  isDisabled?: boolean;
  onChange?: (id: INPUT_ID_TYPE) => void;
  className?: string;
  shouldAnimate?: boolean;
};

const RadioInputCards = <INPUT_ID_TYPE extends string>({
  legend,
  inputName,
  inputs,
  onChange,
  className,
  isDisabled,
  shouldAnimate,
}: RadioInputCardsProps<INPUT_ID_TYPE>): ReactElement => {
  const [activeTab, setActiveTab] = useState<string | null>(() => {
    const selectableItems = inputs.filter(i => i.isDisabled !== true);
    return selectableItems.find(i => i.defaultChecked)?.id || null;
  });

  return (
    <>
      <m.fieldset
        className={classNames(className, s.radioInputCards)}
        initial={(shouldAnimate && { y: 64 }) || undefined}
        animate={(shouldAnimate && { y: 0 }) || undefined}
        transition={(shouldAnimate && { delay: 1 }) || undefined}
      >
        {legend && <legend>{legend}</legend>}
        {inputs.map(i => {
          const isCollection = i.label === NoodleProductTypes.Collection;
          return (
            <label key={i.id}>
              <input
                type="radio"
                id={i.id}
                name={inputName}
                value={i.label}
                disabled={isDisabled || i.isDisabled}
                defaultChecked={activeTab === i.id}
                onClick={() => {
                  setActiveTab(i.id);
                  if (onChange) {
                    onChange(i.id);
                  }
                }}
              />
              {i.icon && !isCollection && <i.icon weight={activeTab === i.id ? 'fill' : 'regular'} size={24} />}
              {isCollection && <Collection size={24} fill={activeTab === i.id ? 'fill' : 'duotone'} />}
              <strong>{i.label}</strong>
              {i.description && <small>{i.description}</small>}
            </label>
          );
        })}
      </m.fieldset>
      <p className={s.extraDescription}>{inputs.find(i => i.id === activeTab)?.extraDescription}</p>
    </>
  );
};

export default RadioInputCards;
