type Output = {
  children: Array<{ text: string }>;
  type: 'paragraph',
};

const createRichTextAst = (text: string): Output => ({
  children: [
    {
      text,
    },
  ],
  type: 'paragraph',
});

export default createRichTextAst;
