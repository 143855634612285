import { Cookie, IconWeight } from '@phosphor-icons/react';
import React, { ReactElement } from 'react';
import s from './Collection.module.scss';

const Collection = ({ size, fill }: { size?: number; fill?: IconWeight }): ReactElement => (
  <div className={s['collection-container']}>
    <Cookie weight={fill} size={size} className={s['collection-container__main-cookie']} />
    <Cookie weight="duotone" size={size} className={s['collection-container__second-cookie']} />
    <Cookie weight="regular" size={size} className={s['collection-container__third-cookie']} />
  </div>
);

export default Collection;
