import { ReactElement } from 'react';
import classNames from 'classnames';
import ArrowsClockwise from '@components/Icons/ArrowsClockwise';
import CheckCircle from '@components/Icons/CheckCircle';
import WarningCircle from '@components/Icons/WarningCircle';
import { JobStatus } from '@providers/Jobs/Context';

import s from './StatusIcon.module.scss';

const StatusIcon = ({ status }: { status: JobStatus }): ReactElement => {
  let Icon = ArrowsClockwise;

  if (status === JobStatus.COMPLETED) {
    Icon = CheckCircle;
  }
  if ([JobStatus.FAILED, JobStatus.CANCELED].includes(status)) {
    Icon = WarningCircle;
  }
  return (
    <div
      className={classNames(s.icon, {
        [s['icon--error']]: [JobStatus.FAILED, JobStatus.CANCELED].includes(status),
        [s['icon--success']]: status === JobStatus.COMPLETED,
      })}
    >
      <Icon weight="fill" />
    </div>
  );
};

export default StatusIcon;
