import { ReactElement } from 'react';
import MinusCircle from 'components/Icons/MinusCircle';
import PlusCircle from 'components/Icons/PlusCircle';
import s from './InputNumberWithControls.module.scss';

interface InputNumberWithControlsProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  label: string;
  required?: boolean;
  error?: string;
}

const InputNumberWithControls = ({
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  disabled,
  label,
  required,
  error,
}: InputNumberWithControlsProps): ReactElement => {
  const handleIncrement = (): void => {
    if (value + step <= max) {
      onChange(value + step);
    }
  };

  const handleDecrement = (): void => {
    if (value - step >= min) {
      onChange(value - step);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = Number(e.target.value);
    // don't allow the user to type in a value that is out of range
    if (e.target.value === '') {
      onChange(min);
    } else if (newValue < min) {
      onChange(min);
    } else if (newValue > max) {
      onChange(max);
    }
    // best case scenario, the user typed in a valid number
    else if (newValue >= min && newValue <= max) {
      onChange(newValue);
    }
  };

  return (
    <label className={s.container}>
      {label}
      <div>
        <button onClick={handleDecrement} disabled={disabled || value - step < min} aria-label={`Remove ${step}`}>
          <MinusCircle weight='fill' size={24} />
        </button>
        <button onClick={handleIncrement} disabled={disabled || value + step > max} aria-label={`Add ${step}`}>
          <PlusCircle weight='fill' size={24} />
        </button>
        <input
          className={s.input}
          type="number"
          value={value.toFixed(0)}
          onChange={handleInputChange}
          disabled={disabled}
          required={required}
          inputMode="numeric"
        />

      </div>
      {error && <span className={s.error}>{error}</span>}
    </label>
  );
};

export default InputNumberWithControls;
